import React, { useEffect, useState } from "react";
import { SHOW_NOTIFICATION } from "action_creators/actioncreator";
//react 
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
// Formik
import { useFormik } from "formik";
// Material UI Components
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import {hotjar} from "react-hotjar";
import GridItem from "components/Grid/GridItem";
import Switch from "@material-ui/core/Switch";
//markdown editor
import Editor from "react-markdown-editor-lite";
import ReactMarkdown from "react-markdown";
import MarkdownIt from 'markdown-it';
//markdown editor import style manually
import 'react-markdown-editor-lite/lib/index.css';
//API Call
import { CMSAPIWithToken } from "../../../apis/CMS";
//checkbox
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';



const useStyles = makeStyles((theme) => ({
  
  gridContainer: {
    padding: "0 40px 30px 40px",
  },
  radioButton: {
    "& .MuiTypography-root": {
      marginLeft: "10px",
    },
  },
  formControl: {
    width: "100%",
    marginTop: "5px",
  },
  Select: {
    marginBottom: "50px",
  },
  warningNote: {
    "& .MuiAlert-icon": {
      display: "none",
    },
  },
  button: {
    marginTop: "50px",
  },
  buttOutlined: {
    color: theme.palette.buttonColor.main,
    borderColor: theme.palette.buttonColor.main,
    "&:hover": {
      border: `1px solid ${theme.palette.buttonColor.main}`,
    },
  },
}));

//sandbox view main
const SandboxTypeUpdate = (props) => {
  const classes = useStyles();
  const mdEditor = React.useRef(null);
  //const [value, setValue] = React.useState("xxx");
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationType, setNotificationType] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [description, setDescription] = useState("");
  //const mdParser = new MarkdownIt(/* Markdown-it options */);
  const dispatch = useDispatch();
    const history = useHistory();
    const inputProps = {
        disableAnimation: true,
        shrink: false
      };  

  const formik = useFormik({
    enableReinitialize:true,
    initialValues: {
                type_name: "", 
                
              },
    onSubmit: (values) => {
      let payload = {
                type_name: values.type_name, 
      };

        CMSAPIWithToken() 
        .patch("/poc/admin_sandboxes_types"+`/${props.user}/`,payload) 
        .then((resp) => resp.data.results)
        .then((data) => {
            const payload = {
                message: "Sandbox Type updated Successfully",
                color: "success",
            };
            payload.message && dispatch(SHOW_NOTIFICATION({ payload }));   
            history.push("/product-admin-sandboxes");
            window.location.reload()
           //renderSanboxView();
        })
        .catch((err) => {
          let message = err.response.data.message;
          const payload = {
            message: message,
            color: "danger",
        };
        payload.message && dispatch(SHOW_NOTIFICATION({ payload })); 
        });
    },
    validate: (values) => {
      let errors = {};
      if (!values.type_name) {
        errors.type_name = "Required";
      }
      return errors;
    },
  });


  let data = [];

  const renderSanboxView = async () => {
    let sanboxView = CMSAPIWithToken()
      .get("/poc/admin_sandboxes_types/")
      .then((resp) => resp.data.results)
      .then((resp) => {
        resp.forEach((item) => {
          if(props.user == item.id) {
            for(const field in item){
              formik.setFieldValue(field, item[field]);
              if(field == 'description'){
                setDescription(item[field])
              }
            }

            return false;
          }
        });
      })      
      .catch((err) => {
        let message = err.response.data.message;
        // setRows([]);
        // setIsLoading(false);
        setShowNotification(true);
        setNotificationType("error");
        setNotificationMessage("Failed to retrieve the data");
      });

      

      //setUserDetails({ ...formik.userDetails, ...data });
  };

  useEffect(() => {
    renderSanboxView();
  }, []);

  //checkbox
  

  return (
    <div>
        <form
            onSubmit={formik.handleSubmit}
            className={classes.formContainer}
          >
      <Grid
        container
        justify="space-between"
        alignItems="center"
        className={classes.gridContainer}
      >
        <Grid item xs={12} md={5}>
          <Typography variant="h4">Sandbox Update</Typography>
          
        </Grid>

        <Grid item xs={12} md={7}>
        <TextField
          type="text"
          variant="filled"
          label="Type Name"
          name="type_name"
          fullWidth
          InputLabelProps=""
          inputProps=""
          value={formik.values.type_name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        /> 
        </Grid>

        <GridItem xs={12} sm={6} md={3} lg={3}>
                 <Button
                    variant="contained"
                    color="secondary"
                    disableElevation
                    size="large"
                    className={classes.buttStyle}
                    fullWidth
                    disableFocusRipple
                    type="submit"
                  >
                    Submit
                  </Button>
              </GridItem>

      </Grid>
      </form>       
    </div>
  );
};

export default SandboxTypeUpdate;
