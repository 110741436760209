import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {CircularProgress } from '@material-ui/core/';


// Redux
import { useDispatch } from "react-redux";
import {
  SHOW_LOADER,
  HIDE_LOADER,
  SET_NAVBAR_TITLE
} from "action_creators/actioncreator";

// Material UI Components
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

// Icons
import WarningTwoTone from '@material-ui/icons/WarningTwoTone';
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ListIcon from "@material-ui/icons/List";
import AddIcon from "@material-ui/icons/Add";

// Local Components
import ExerciseHeader from "./ExerciseHeader";
import Menu from "views/Components/Menu";
import { SDCloudBackendAPIWithToken } from "apis/backendAPI";
import { Api } from "config/api";
import { CMSAPIWithToken } from "apis/CMS";
import SandboxAccessDevices from "./SandboxAccessDevices";
import Overview from "./Overview";
import Exercises from "./Exercises";
import Cheatsheets from "./Cheatsheet"
import AddHoursSandboxModal from "views/Components/ServiceOffering/AddHoursSandboxModal";
import SandboxSettings from "./SandboxSettings";
import Access from "./Access";
import { Typography } from "@material-ui/core";
import ResumeSandboxModal from "../ResumeSandboxModal";
import SandboxScheduleModal from "../SandboxScheduleModal";
import { SHOW_NOTIFICATION } from "action_creators/actioncreator";

const useStyles = makeStyles(theme => ({
  dialogtitle:{
      fontSize:"1.5rem",
      fontFamily:"Roboto, Helvetica, Arial, sans-serif",
      fontWeight: 500,
      lineHeight: 1.6,
      letterSpacing: "0.0075em",
      width:485,
      textAlign:"center"
  },
  backButton: {
    margin: "15px 0",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    "& svg": {
      marginRight: "5px"
    },
    "&:hover": {
      color: theme.palette.secondary.main,
      "& svg": {
        fill: theme.palette.secondary.main
      }
    }
  },
  btnColor: {
    color: `${theme.palette.buttonColor.main} !important`,
    border: `1px solid ${theme.palette.buttonColor.main} !important`,
    "&:hover": {
      color: `${theme.palette.buttonColor.main} !important`,
      border: `1px solid ${theme.palette.buttonColor.main} !important`
    },
    "& .Mui-disabled": {
      color: `${theme.palette.buttonColor.main} !important`,
      border: `1px solid ${theme.palette.buttonColor.main} !important`
    }
  },
  menuIcon: {
    width: "30px",
    height: "30px",
    color: theme.palette.secondary.main,
    "&:hover": {
      cursor: "pointer"
    }
  },
  // menuItems: {
  //   minHeight: "450px",
  //   display: "block",
  //   fontSize: "14px",
  //   marginTop: "30px"
  // },
  menuItemsWrapper: {
    maxHeight: "450px", 
    overflowY: "auto",
    position: "relative", 
    maxWidth:"200px"
  },
  button:{
    position: "relative",
    // top: "-10rem",
    paddingTop:"30px"
  },
}));

function AccessSandbox() {
  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();

  const [clusterDetails, setClusterDetails] = useState({});
  const [currentScreen, setCurrentScreen] = useState("Overview");
  const [sandboxDetails, setSandboxDetails] = useState({});
  const [sandboxCategories, setSandboxCategories] = useState([]);
  const [sandboxCheatsheets, setSandboxCheatsheets] = useState([]);
  const [sandboxExercises, setSandboxExercises] = useState([]);
  const [categoryNames, setCategoryNames] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [clusterStatus, setClusterStatus] = useState("");
  const [clusterRunningTime, setClusterRunningTime] = useState("");
  const [clusterRemainingTime, setClusterRemainingTime] = useState("");
  const [clusterTotalTime, setClusterTotalTime] = useState("");
  const [runTimeMinutes, setRunTimeMinutes] = useState("");
  const [templateName, setTemplateName] = useState("");
  const [showMenu, setShowMenu] = useState(true);
  const [openResumeModal, setOpenResumeModal] = useState(false);
  const [OpenScheduleResumeModal, setOpenScheduleResumeModal] = useState(false);
  const [hideAddHoursModal, setHideAddHoursModal] = useState(true);
  const [cheatSheetOption, setCheatSheetOption] = useState(false);
  const [exportConfigButton, setExportConfigButton] = useState(false);
  let userProfile = JSON.parse(localStorage.getItem("profileData"));
  let userAccess = userProfile.add_hours.user_access;
  let adminAccess = userProfile.add_hours.admin_access;
  var profileData = JSON.parse(localStorage.getItem("profileData"));
	var previlege = profileData.privilege;
  const account = profileData.account;
  let domain = JSON.parse(localStorage.getItem("internal_domains"))?.domains;
  let userEmail = JSON.parse(localStorage.getItem("profileData"))?.email;
  let hasDomain = domain?.some((res) => userEmail.includes(res));
  const [isInternal, setIsInternal] = useState(hasDomain);
  const [action,setAction] = useState("")
  const [categoryIndex, setCategoryIndex] = useState(0);
  const [exerciseIndex, setExerciseIndex] = useState(0);
  const menuItems = ["Overview", ...categoryNames , "Cheat sheets", "Access devices"]; // "Sandbox Settings";
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isArchive, setIsArchive] = useState(false);
  const hideloader1 = ()=>{
    setLoading(!loading)
  }
  const [isLiveeditDone,setIsLiveeditDone] = useState(false);
  const [imagename, setImageName] = useState("");

  const handleClickOpen = (e,val) => {
    setAction(val)
    setOpen(true);
  };
  const handledialclose=(event,val)=>{
    if (event.target === event.currentTarget) {
      // Clicked outside the dialog
      handleClose();
    }
  }
  const handleClose = (event,val) => {
    
    if(val=='terminate'){
      handleTerminateButton()
    }
    else if(val=='schedule'){
      handleResumeScheduleCluster(val)
    }
    else{
      handleModifyClusterStatus()
    }
    setOpen(false);
  };
  const handleResumeScheduleCluster = (input) =>{
    if (input === "schedule"){
      setOpenScheduleResumeModal(true);
    }
  }

  const getClusterDetails = () => {
    if (params.uuid === null) {
      return null;
    }

    // //console.log("UUID",params.uuid)

    SDCloudBackendAPIWithToken()
      .get(Api.clusterStatus(params.uuid))
      .then(resp => resp.data)
      .then(data => {
        setClusterDetails({ ...data });
        if (data.is_live_edit_done == "true"){
          setIsLiveeditDone(true)
        }
        let underlay_ubuntu_version = data.underlay_ubuntu_version
        if (underlay_ubuntu_version == "22"){
          setExportConfigButton(true)
        }
        if (data.status === "suspending" || data.status === "suspended") {
          setClusterStatus("resume");
        } else {
          setClusterStatus("suspend");
        }
        if(data.status === "suspended"){
          setIsArchive(data.is_archive)
        }
        if (data.provision_time && data.terminate_time && data.end_time) {
          let terminate_time = new Date(data.terminate_time.replace(/ /g, "T"));
          let provision_time = new Date(data.provision_time.replace(/ /g, "T"));
          let endTime = new Date(data.end_time.replace(/ /g, "T"));
          var d = new Date();
          if(data.hasOwnProperty("suspended_hours")){
            d.setMinutes(d.getMinutes() - (data.suspended_hours*60));
          }
          let clusterRunTime = d - provision_time;
          let remainingTime = 0
          if(endTime > new Date()){
            remainingTime = endTime - new Date();
          }
          // let remainingTime = endTime - new Date();
          let totalTime = endTime - provision_time;
          // if (new Date() > endTime) {
          //   clusterRunTime = totalTime;
          // }
          if(data.status == "suspended" || data.status == "archived"){
            clusterRunTime = clusterRunTime
            remainingTime = 0
          } else if (new Date() > endTime) {
              clusterRunTime = totalTime;
          }
          let clusterRunTimeMinutes = clusterRunTime / (1000 * 60).toFixed(2);
          let remainingTimeMinutes = remainingTime / (1000 * 60).toFixed(2);
          setClusterTotalTime(totalTime / (1000 * 60).toFixed(2));
          setRunTimeMinutes(clusterRunTimeMinutes);
          if(data.status=='resuming'){
            setClusterRunningTime("Fetching....");
            setClusterRemainingTime("Fetching....");
          } else {
            if (clusterRunTimeMinutes < 60.0) {
              setClusterRunningTime(`${parseInt(clusterRunTimeMinutes)} minutes`);
            } else {
              let clusterRunTimeHours = parseInt(clusterRunTimeMinutes / 60);
              setClusterRunningTime(
                `${clusterRunTimeHours} hours ${parseInt(
                  clusterRunTimeMinutes % 60
                )} minutes`
              );
            }
            if (remainingTimeMinutes < 60.0) {
              setClusterRemainingTime(
                `${parseInt(remainingTimeMinutes)} minutes`
              );
            } else {
              let remainingTimeHours = parseInt(remainingTimeMinutes / 60);
                setClusterRemainingTime(
                    `${remainingTimeHours} hours ${parseInt(
                        remainingTimeMinutes % 60
                    )} minutes`
                );
            }
          }
          // if (new Date() > endTime) {
            // setClusterRemainingTime(`${parseInt(0)} minutes`);
            // setRunTimeMinutes(totalTime / (1000 * 60).toFixed(2));
          // }
        } else {
          setClusterTotalTime(0);
          setRunTimeMinutes(0);
          setClusterRunningTime("Fetching....");
          setClusterRemainingTime("Fetching....");
        }

        dispatch(HIDE_LOADER());
      });
  };

  const getAdminSandboxesDetails = async adminSandboxId => {
    CMSAPIWithToken()
      .get(Api.adminSandboxesDetails, {
        params: {
          admin_sandboxes_id: adminSandboxId
        }
      })
      .then(resp => resp.data)
      .then(data => {
        setSandboxDetails({ ...data.results[0] });
      });
  };

  const getAdminSandboxCategories = async adminSandboxId =>{
    CMSAPIWithToken()
      .get(Api.adminSandboxesCategories, {
        params: {
          admin_sandboxes_id: adminSandboxId
        }
      })
      .then(resp => resp.data)
      .then(data => {
        const sortedCategories = data.results.slice().sort((a, b) =>
        a.category_position - b.category_position)
        const categoryNames = sortedCategories.map(category => category.sandboxes_category_name);
        setCategoryNames(categoryNames)
        setSandboxCategories([sortedCategories]);
      });
  };
  const getAdminSandboxCheatsheets = async adminSandboxId =>{
    CMSAPIWithToken()
      .get(Api.adminSandboxesCheatsheets, {
        params: {
          admin_sandboxes_id: adminSandboxId
        }
      })
      .then(resp => resp.data)
      .then(data => {
        if(data.results.length==0){
          setCheatSheetOption(true)
        }
        setSandboxCheatsheets([data.results]);
      });
  };
  

  const getAdminSandboxExercises = async adminSandboxId =>{
    CMSAPIWithToken()
      .get(Api.adminSandboxesExercises, {
        params: {
          admin_sandboxes_id: adminSandboxId
        }
      })
      .then(resp => resp.data)
      .then(data => {
        setSandboxExercises([data.results]);
      });
  };
  const getAdminSandboxes = async () => {
    CMSAPIWithToken()
      .get(Api.adminSandboxes, {
        params: {
          limit: 1000
        }
      })
      .then(resp => resp.data)
      .then(data => {
        let sandboxList = data.results;

        const sandbox = sandboxList.filter(item => {
          return item.identity === params.identifier;
        });

        const adminSandboxId = sandbox[0].id;
        setTemplateName(sandbox[0].subtitle);

        getAdminSandboxesDetails(adminSandboxId);
        getAdminSandboxCategories(adminSandboxId);
        getAdminSandboxCheatsheets(adminSandboxId);
        getAdminSandboxExercises(adminSandboxId);
      })
      .catch(err => {
        //console.log(err);
      });
  };
  const changeActiveTab = (newTab) => {
      setExerciseIndex(0)
      setCurrentScreen(newTab);
  };
  const changePrevActiveTab = (newTab,exerciseindex,categoryindex) => {
    if(exerciseindex || categoryindex ){
      setCurrentScreen(newTab);
      setExerciseIndex(exerciseindex)
      setCategoryIndex(categoryindex)
    } else {
      setCurrentScreen(newTab);
    }
  };
  const renderExercise = () => {
    switch (currentScreen) {
      case "Overview":
        return <Overview data={sandboxDetails} exercisesData={sandboxExercises} categoriesData={sandboxCategories} sandboxCheatsheets={sandboxCheatsheets[0]} changeActiveTab={changeActiveTab} />;
      case "Access devices":
        return (
          <SandboxAccessDevices
            data={sandboxDetails}
            clusterDetails={clusterDetails}
            exercisesData={sandboxExercises} 
            categoriesData={sandboxCategories}
            sandboxCheatsheets={sandboxCheatsheets[0]}
            changePrevActiveTab={changePrevActiveTab}
            changeActiveTab={changeActiveTab}
          />
        );
      case "Cheat sheets":
        return <Cheatsheets data={sandboxCheatsheets[0]} exercisesData={sandboxExercises} categoriesData={sandboxCategories} changeActiveTab={changeActiveTab} cheatsheetIndex={exerciseIndex} changePrevActiveTab={changePrevActiveTab} clusterDetails={clusterDetails}/>;
      case "Access":
        return <Access clusterDetails={clusterDetails} />;
      // case "Sandbox Settings":
      //   return (
      //     <SandboxSettings
      //       status={clusterStatus}
      //       clusterDetails={clusterDetails}
      //     />
      //   );
      default:
        if (categoryNames.includes(currentScreen) && sandboxCategories ) {
          return <Exercises exercises={sandboxExercises} categories={sandboxCategories} data={currentScreen} sandboxCheatsheets={sandboxCheatsheets[0]} changeActiveTab={changeActiveTab} categoryIndex={categoryIndex} exerciseIndex={exerciseIndex} clusterDetails={clusterDetails}/>;
        } else {
          return null;
        }
    }
  };

  useEffect(() => {
    // Cleanup function to cancel any ongoing processes when the component is unmounted
    return () => {
      // Perform cleanup here, such as canceling requests, subscriptions, etc.
      // Make sure to check if the component is still mounted before updating the state
      if (loading) {
        setLoading(false);
      }
    };
  }, [loading]);

  const exportconfig = () => {
    setLoading(true);
    toast.info('Exporting configurations. Please wait...', { autoClose: false });
    // Set the values for template_name, template_uuid, and action
    const template_name = clusterDetails.clustername;
    const template_uuid = params.uuid;
    const action = "export_all_frozen";
  
    SDCloudBackendAPIWithToken()
      .post(Api.exportSandboxconfig, {
        
        "action": action,
        "template_uuid": template_uuid,
        "template_name": template_name,
      })
      .then(response => {
        hideloader1()       
        // Log the variables and response data
        let zipData = response.data.download_info.content;
        let filename = response.data.download_info.output_file_name
                try{
                    const byteCharacters = atob(zipData);
                    const byteArrays = [];
  
                    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
                        const slice = byteCharacters.slice(offset, offset + 512);
  
                        const byteNumbers = new Array(slice.length);
                        for (let i = 0; i < slice.length; i++) {
                            byteNumbers[i] = slice.charCodeAt(i);
                        }
  
                        const byteArray = new Uint8Array(byteNumbers);
                        byteArrays.push(byteArray);
                    }
                    const blob = new Blob(byteArrays, { type: 'application/zip' });
                    
                    // Create an anchor tag for our Blob
                    const a = document.createElement('a');
                    document.body.appendChild(a);
                    a.style = 'display: none';
                    const url = window.URL.createObjectURL(blob);
                    a.href = url;
                    a.download = filename;
                    a.click();
                    window.URL.revokeObjectURL(url);
                }
                catch (error) {
                    console.error('Error:', error);
                    
                }finally {
                  // Hide the loader after processing the response
                  setLoading(false);
                }
      })
      .catch(error => {
        console.error("API request failed:", error);
      });
  };
  const fetchImageDetails = async () => {
    try {
      let payload = { name: params.uuid };
      const resp = await SDCloudBackendAPIWithToken().post("get_image_details/", payload);
      const data = resp.data;
      let image_details = data.image_details;
  
      setImageName(image_details["image_name"]);
  
      if (image_details) {
        return image_details["image_name"];
      }
    } catch (err) {
      dispatch(HIDE_LOADER());
      let message = err.response.data.message;
      // Handle error if needed
      throw err; // Rethrow the error to propagate it further
    }
  };
  
  const freezeSandbox = (data) =>{

    SDCloudBackendAPIWithToken()
          .post(Api.freeze, data)
          .then(resp => {
            const payload = {
              message: resp?.data?.message || "Successfully published template",
              color: "success",

            };
            dispatch(SHOW_NOTIFICATION({ payload }));

          })
          .catch(err => {
            const payload = {
              message: err?.response?.data?.message || "Something went wrong, Try later..",
              color: "danger",
              error: err
            };
            dispatch(SHOW_NOTIFICATION({ payload }));
          });
  }
  const handleUpgradeSandbox = async () => {
    try {
  
      let payload = {
        uuid: params.uuid,
      };
  
      const resp = await SDCloudBackendAPIWithToken().post(Api.updateLiveeditTemplate, payload);
  
      if (resp.status === 200) {
        var imagename1 = await fetchImageDetails();
  
        let freezePayload = {
          "uuid": resp.data.data.uuid,
          "image_name": imagename1,
          "sku_name": resp.data.data.sku_name,
          "sku_id": resp.data.data.Sku_id,
          "offering": "upgrade"
        };
  
        const freez_status = await freezeSandbox(freezePayload);
      }
    } catch (err) {
      // Handle errors here
      // const payload = {
      //   message: err?.response?.data?.message || "unknown error occurred",
      //   color: "danger",
      //   error: err
      // };
      // dispatch(SHOW_NOTIFICATION({ payload }));
    }
  };
  

  const handleModifyClusterStatus = () => {
    if (clusterStatus === "suspend") {
        let payload = {
          uuid: params.uuid,
          action: clusterStatus
        };

        SDCloudBackendAPIWithToken()
          .post(Api.modifyCluster, payload)
          .then(resp => resp.data)
          .then(data => {
            let action = clusterStatus === "suspend" ? "suspended" : "resumed";
            const payload = {
              message: `Sandbox successfully ${action}`,
              color: "success"
            };

            dispatch(SHOW_NOTIFICATION({ payload }));
            window.location.reload();
          })
          .catch(err => {
            // //console.log(err);
            // const payload = {
            //   message: err?.response?.data?.message || "unknown error occured",
            //   color: "danger",
            //   error: err
            // };
            // dispatch(SHOW_NOTIFICATION({ payload }));
          });
      
    } else {
        setOpenResumeModal(true);
    }
  };

  const handleBackClick = (clusterDetails) => {
    if (["suspended","archived"].includes(clusterDetails.status)) {
      history.push('/experience-center-sandboxes', { currentTab: 'Inactive' });
    } else {
      history.push('/experience-center-sandboxes', { currentTab: 'Active' });
    }
  };

  const handleTerminateButton = () => {
    setOpen(false);
      let payload = {
        uuid: params.uuid
      };

      SDCloudBackendAPIWithToken()
        .post(Api.terminateCluster, payload)
        .then(resp => resp.data)
        .then(data => {
          history.push("/experience-center-sandboxes", {
            currentTab: "Active"
          });
        })
        .catch(err => {
          // //console.log(err);
          // const payload = {
          //   message: err?.response?.data?.message || "unknown error occured",
          //   color: "danger",
          //   error: err
          // };
          // dispatch(SHOW_NOTIFICATION({ payload }));
        });
    }

  const showAddHoursModal = () => {
    setHideAddHoursModal(false);
  };
  const hideHoursModal = () => {
    setHideAddHoursModal(true);
  };

  useEffect(() => {
    dispatch(SHOW_LOADER());
    dispatch(SET_NAVBAR_TITLE(isInternal?account+"  - Experience Center - Sandboxes":"Experience Center - Sandboxes"));

    getClusterDetails();
    getAdminSandboxes();

    let statusUpdateInterval = setInterval(() => {
      getClusterDetails(statusUpdateInterval);
    }, 15000);

    return () => {
      clearInterval(statusUpdateInterval);
    };
  }, []);

  return (
    <Grid container>
      <div>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle >
          <Typography  className={classes.dialogtitle} variant="h1"> {`Are you sure you want to ${action} this Lab?`}</Typography>
        </DialogTitle>
        {action=='terminate' &&
        <DialogContent>
          <DialogContentText style={{
            color: "rgba(0, 0, 0, 0.6)",
            fontFamily: "Roboto, Helvetica, Arial, sans-serif",
            fontWeight: 400,
            fontSize: "1rem",
            }}><WarningTwoTone style={{
              marginRight:"10px",
              position:"relative",
              top:9,
              color:"red",
              fontSize:"3rem"
              }}/>  
            Once you terminate the Sandbox you will loose your changes.
          </DialogContentText>
        </DialogContent>}
        {action=='suspend' &&
        <DialogContent>
          <DialogContentText style={{
            color: "rgba(0, 0, 0, 0.6)",
            fontFamily: "Roboto, Helvetica, Arial, sans-serif",
            fontWeight: 400,
            fontSize: "1rem",
            textAlign:"center"
            }}><WarningTwoTone style={{
              marginRight:"10px",
              position:"relative",
              top:9,
              color:"red",
              fontSize:"3rem",
              }}/>  
            Note: Suspending the lab will stop all active processes.
          </DialogContentText>
        </DialogContent>}
        {action=='resume' &&
        <DialogContent>
          <DialogContentText style={{
            color: "rgba(0, 0, 0, 0.6)",
            fontFamily: "Roboto, Helvetica, Arial, sans-serif",
            fontWeight: 400,
            fontSize: "1rem",
            }}>  
            The Sandbox will resume now.
          </DialogContentText>
        </DialogContent>}
        <DialogActions>
          <Button style={{width:100,color:'#F96B13',border:'1px solid #F96B13'}} onClick={()=>setOpen(false)}>Cancel</Button>
          <Button style={{width:100,color:'#F96B13',border:'1px solid #F96B13'}} onClick={(e)=>handleClose(e,action)} >
            Proceed
          </Button>
        </DialogActions>
      </Dialog>
    </div>
      <Grid item xs={12} md={3} lg={3}>
        <Typography
          variant="body1"
          className={classes.backButton}
          onClick={() => handleBackClick(clusterDetails)}
        >
          <ArrowBackIcon />
          Back
        </Typography>
      </Grid>
      <Grid item xs={12} md={12}>
        <ExerciseHeader
          title={clusterDetails.clustername}
          clusterStatus={clusterDetails.status}
          clusterPrevious_status={clusterDetails.previous_status}
          clusterRunningTime={clusterRunningTime}
          clusterRemainingTime={clusterRemainingTime}
          clusterTotalTime={clusterTotalTime}
          runTimeMinutes={runTimeMinutes}
          subtitle={templateName}
        />
      </Grid>
      <Grid item md={12}>
        <Grid container>
          <Grid item md={showMenu ? 2 : 1}>
            <div>
              <Tooltip title="Menu">
                {showMenu ? (
                  <ListIcon
                    className={classes.menuIcon}
                    onClick={() => setShowMenu(!showMenu)}
                  />
                ) : (
                  <MoreVertIcon
                    className={classes.menuIcon}
                    onClick={() => setShowMenu(!showMenu)}
                  />
                )}
              </Tooltip>
            </div>
            <div className={classes.menuItemsWrapper}>
            {showMenu && (
            <div className={classes.menuItems}>
              {cheatSheetOption ? 
              <Menu
                items={menuItems.filter(item => item !== "Cheat sheets")}
                onItemClick={setCurrentScreen}
                currentScreen={currentScreen}
                showMenu={showMenu}
              />:
              <Menu
                items={menuItems}
                onItemClick={setCurrentScreen}
                currentScreen={currentScreen}
                showMenu={showMenu}
              />}
            </div>
            )}
            </div>
            <Grid item xs={12} sm={10} md={10} className={classes.button}>
              <div>
                <ResumeSandboxModal
                  open={openResumeModal}
                  uuid={clusterDetails.uuid}
                  action={clusterStatus}
                  name={clusterDetails.clustername}
                  archiveaction={isArchive}
                  onClose={setOpenResumeModal}
                />
                {clusterDetails.status === "suspended" ? (
                <SandboxScheduleModal
                  open={OpenScheduleResumeModal}
                  uuid={clusterDetails.uuid}
                  action={clusterStatus}
                  identifier={params?.identifier}
                  name={clusterDetails.clustername}
                  onClose={setOpenScheduleResumeModal}
                  setData={{
                    showNotification: false,
                    notificationMessage: "",
                    notificationType: ""
                  }}
                  initialValues={{
                    name: "",
                    startDate: null,
                    startTime: null,
                    run_time: null
                  }}
                  title="Sandbox"
                />) : null }
                {hideAddHoursModal ? null : (
                  <AddHoursSandboxModal
                    addHoursDetails={{
                      uuid: params?.uuid,
                      labIdentifier: params?.identifier,
                      addhoursLimit: userProfile?.add_hours?.hours_limit
                    }}
                    openModal={hideAddHoursModal}
                    hideModal={hideHoursModal}
                  />
                )}
                {clusterDetails.status !== "not provisioned" ? (
                  <Button
                    variant="outlined"
                    color="secondary"
                    disableElevation
                    size="large"
                    className={classes.buttOutlined}
                    fullWidth
                    onClick={showAddHoursModal}
                    disableFocusRipple
                    disabled={["suspended","archived","suspending","resuming"].includes(clusterDetails.status)}
                  >
                    {/* <span
                        className="add-hours"
                        onClick={props.showAddHoursModal}
                      > */}
                    <AddIcon className={classes.addHoursIcon} />
                    Add hours
                    {/* </span> */}
                  </Button>
                ) : null}
                <Button
                  variant="outlined"
                  color="secondary"
                  disableElevation
                  size="large"
                  className={classes.btnColor}
                  fullWidth
                  onClick={(e) => handleClickOpen(e,clusterStatus === "suspend" ? "suspend" : "resume")}
                  disableFocusRipple
                  disabled={
                    ["ready", "suspended"].includes(clusterDetails.status) ||
                    clusterStatus === "suspended"
                      ? false
                      : true
                  }
                >
                  {clusterStatus === "suspend" ? "Suspend" : "Resume"}
                </Button>
                {clusterDetails.status === "suspended" ? (
                <Button
                  variant="outlined"
                  color="secondary"
                  disableElevation
                  size="large"
                  className={classes.btnColor}
                  fullWidth
                  onClick={(e) => handleClickOpen(e,"schedule")}
                  disableFocusRipple
                  disabled={
                    ["suspended"].includes(clusterDetails.status) ||
                    clusterStatus === "suspended"
                      ? false
                      : true
                  }
                >
                  Resume Scheduler
                </Button>
              ):null}
                <Button
                  variant="outlined"
                  color="secondary"
                  disableElevation
                  size="large"
                  className={classes.btnColor}
                  fullWidth
                  onClick={(e) => handleClickOpen(e,"terminate")}
                  disableFocusRipple
                  disabled={
                    ["ready", "suspended", "error"].includes(
                      clusterDetails.status
                    ) || clusterStatus === "suspended"
                      ? false
                      : true
                  }
                >
                  Terminate
                </Button>
                {exportConfigButton ? (
                <Button
                  variant="outlined"
                  color="secondary"
                  disableElevation
                  size="large"
                  className={classes.btnColor}
                  fullWidth
                  onClick={exportconfig}
                  disableFocusRipple
                  disabled={
                    clusterDetails.status !== "ready" ||
                    loading // Disable the button if loading is true
                  }
                >
                  {loading ? (
                    <CircularProgress size={24} color="secondary" /> // Show loading indicator
                  ) : (
                    'Export Configurations'
                  )}
                </Button>):null}

                {isLiveeditDone && previlege == 'admin' && 
                <Button
                  variant="outlined"
                  color="secondary"
                  disableElevation
                  size="large"
                  className={classes.btnColor}
                  fullWidth
                  onClick={(e) => handleUpgradeSandbox()}
                  disableFocusRipple
                  disabled={
                    ["ready"].includes(
                      clusterDetails.status
                    ) || clusterStatus === "ready"
                      ? false
                      : true
                  }
                >
                  Publish  
                </Button>}
              </div>
            </Grid>
            {/* <SandboxSettings
              status={clusterStatus}
              clusterDetails={clusterDetails}
            /> */}
          </Grid>
          <Grid item md={showMenu ? 10 : 11}>
            {renderExercise()}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default AccessSandbox;
