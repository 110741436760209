import { SHOW_NOTIFICATION } from "action_creators/actioncreator";
// import style manually
import 'react-markdown-editor-lite/lib/index.css';

// Finish!

// This component creates a modal for Reserved IP with respective form fields, the
// modal is popped up during provision or resuming of a lab or poc.

import React, { Component, useState, useEffect } from "react";
import { SECONDARY_COLOR } from "global_constants/app_constants";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
// Formik
import { useFormik } from "formik";
import MuiPhoneNumber from "material-ui-phone-number";
import Datetime from "react-datetime";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { Api, Urls } from "config/api";
import Loader from "react-loader-spinner";
import LoaderHoc from "../../../components/HOC/LoaderHoc";
import { LOADER_TYPE } from "global_constants/app_constants";
import "views/ServiceOffering/LearningLab/acess labs/Overview.scss";
import NotificationAlert from "views/Components/NotificationAlert";
import { SDCloudBackendAPIWithToken } from "apis/backendAPI";
import Typography from "@material-ui/core/Typography";
import theme from "theme";
//API Call
import { CMSAPIWithToken } from "../../../apis/CMS";
//checkbox
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';
import Switch from "@material-ui/core/Switch";
//react 
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

//markdown
import Editor from "react-markdown-editor-lite";
import ReactMarkdown from "react-markdown";
import MarkdownIt from 'markdown-it';


const inputProps = {
  disableAnimation: true,
  shrink: false
};

const customStyles = theme => ({
  mainContainer: {
    padding: "40px"
  },
  container: {
    marginTop: "20px"
  },
  formContainer: {
    width: "100%"
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  paper: {
    position: "absolute",
    width: 550,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    display: "block"
  },
  buttStyle: {
    background: theme.palette.buttonColor.main,
    "&:hover": {
      background: theme.palette.buttonColor.main
    }
  },
  firstTitleLabel: {
    padding: "0 15px"
  },
  titleLabel: {
    padding: "0 15px",
    marginTop: "30px"
  },
  errorText: {
    fontSize: "12px",
    color: theme.palette.error.main
  }
});

//mark down editor
function handleEditorChange({ html, text }) {
  //console.log('handleEditorChange', html, text);
}
// Initialize a markdown parser
const mdParser = new MarkdownIt(/* Markdown-it options */);


const AddNewSandbox = props => {
  const useStyles = makeStyles(customStyles);
  const classes = useStyles();
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationType, setNotificationType] = useState("");
  const [value, setValue] = React.useState("ipv4");
  const [description, setDescription] = useState('');
  const [sandboxTypesData, setSandboxTypesData] = useState([]);
  const mdEditor = React.useRef(null);
  const mdParser = new MarkdownIt(/* Markdown-it options */);
  const dispatch = useDispatch();
    const history = useHistory();

  useEffect(() => {
    getAccountInfo();
    getSandboxTypes();
  }, []);

  const formik = useFormik({
    enableReinitialize:true,
    initialValues: {
                title: "", 
                subtitle: "",
                identity: "",
                admin_sandboxes_type_id: '', 
                price: "",
                price_unit: "", 
                description: "", 
                diagram: "",
                diagram_img: "", 
                icon_image: "", 
                email_content: "",
                is_enabled: false, 
                access_impairment: false, 
                access_tcpreplay: false, 
                account: [ ]
              },
    onSubmit: (values) => {
      let payload = {
                title: values.title, 
                subtitle: values.subtitle ,
                identity: values.identity, 
                price: values.price,
                admin_sandboxes_type_id: values.admin_sandboxes_type_id,
                price_unit: values.price_unit, 
                description:description, 
                diagram: values.diagram,
                diagram_img:values.diagram_img, 
                icon_image: values.icon_image, 
                email_content: values.email_content,
                is_enabled: values.is_enabled, 
                access_impairment:values.access_impairment, 
                access_tcpreplay: values.access_tcpreplay, 
                account: [ ]
      };

        CMSAPIWithToken() 
        .post("/poc/admin_sandboxes/",payload) 
        .then((resp) => resp.data.results)
        .then((data) => {
            const payload = {
                message: "Sandbox Created Successfully",
                color: "success",
            };
            payload.message && dispatch(SHOW_NOTIFICATION({ payload }));   
            history.push("/product-admin-sandboxes");
            window.location.reload()
        })
        .catch((err) => {
          let message = err.response.data.message;
          const payload = {
            message: message,
            color: "danger",
        };
        payload.message && dispatch(SHOW_NOTIFICATION({ payload })); 
        });
    },
    validate: (values) => {
      let errors = {};
      if (!values.title) {
        errors.title = "Required";
      }
      if (!values.subtitle) {
        errors.subtitle = "Required";
      }
      if(!values.identity){
        errors.identity = "Required";
      }
      return errors;
    },
  });

  //mark down editor
 const handleEditorChange = ({ html, text }) => {
  const newValue = text;
  //console.log(newValue);
  setDescription(newValue);
};

  const getSandboxTypes = () => {
    let typeData = []
    CMSAPIWithToken()
    .get("/poc/admin_sandboxes_types/")
    .then((resp) => resp.data.results)
      .then((resp) => {
        resp.forEach((item) => {
          typeData.push({
              id: item.id,
              sandboxes_type_name: item.type_name,
            });   
        });
        setSandboxTypesData(typeData);
        setIsLoading(false);
      })
      .catch((err) => {
        let message = err.response.data.message || "unknown error occured";
        setIsLoading(false);
      });
  };


  const getAccountInfo = () => {
    SDCloudBackendAPIWithToken()
      .get(Api.accountInfo)
      .then(res => {
      
      })
      .then(() => {
        setIsLoading(false);
      })
      .catch(err => {
        //console.log(err.response);
        setIsLoading(false);
      });
  };
let Cancelbutton=()=>{
  history.push("/product-admin-sandboxes");
  window.location.reload()
}
  const [state, setState] = React.useState({
    checkedA: true,
    checkedB: true,
    checkedF: true,
    checkedG: true,
  });

const handleChange = (event) => {
  setState({ ...state, [event.target.name]: event.target.checked });
};

  return (
    // Form modal for sandbox create
    <div>
      <LoaderHoc isLoading={isLoading}>
        <Paper elevation={0}>
          <Grid
            container
            className={classes.mainContainer}
            style={{ marginTop: "2%" }}
          >
            <form
              onSubmit={formik.handleSubmit}
              className={classes.formContainer}
            >
              {/* <Typography variant="h4">{props.formTitle}</Typography> */}
              <Typography variant="h4" className={classes.firstTitleLabel}>
              Add New Sandbox
              </Typography>
              <Grid container className={classes.container}>
                <GridItem md={9}>
                  {formik.errors.title && formik.touched.title ? (
                    <TextField
                      type="text"
                      variant="filled"
                      label="Title"
                      name="title"
                      fullWidth
                      InputLabelProps={inputProps}
                      value={formik.values.title}
                      onChange={formik.handleChange}
                      helperText={formik.errors.title}
                      onBlur={formik.handleBlur}
                      error
                    />
                  ) : (
                    <TextField
                      type="text"
                      variant="filled"
                      label="Title"
                      name="title"
                      fullWidth
                      InputLabelProps={inputProps}
                      value={formik.values.title}
                      onChange={formik.handleChange}
                    />
                  )}
                </GridItem>
                <GridItem md={9}>
                  {formik.errors.identity && formik.touched.identity ? (
                    <TextField
                      type="text"
                      variant="filled"
                      label="Subtitle"
                      name="subtitle"
                      fullWidth
                      InputLabelProps={inputProps}
                      value={formik.values.subtitle}
                      onChange={formik.handleChange}
                      helperText={formik.errors.subtitle}
                      onBlur={formik.handleBlur}
                      error
                    />
                  ) : (
                    <TextField
                      type="text"
                      variant="filled"
                      label="Subtitle"
                      name="subtitle"
                      fullWidth
                      InputLabelProps={inputProps}
                      value={formik.values.subtitle}
                      onChange={formik.handleChange}
                    />
                  )}
                </GridItem>
                <GridItem md={9}>
                  {formik.errors.identity && formik.touched.identity ? (
                    <TextField
                      type="text"
                      variant="filled"
                      label="Identity"
                      name="identity"
                      fullWidth
                      InputLabelProps={inputProps}
                      value={formik.values.identity}
                      onChange={formik.handleChange}
                      helperText={formik.errors.identity}
                      onBlur={formik.handleBlur}
                      error
                    />
                  ) : (
                    <TextField
                      type="text"
                      variant="filled"
                      label="Identity"
                      name="identity"
                      fullWidth
                      InputLabelProps={inputProps}
                      value={formik.values.identity}
                      onChange={formik.handleChange}
                    />
                  )}
                </GridItem>
                <GridItem md={9}>
                <InputLabel style={{marginTop:"15px", width: "82%"}} id="admin_sandboxes_type_id">
                    Admin Sandboxe Type
                  </InputLabel>
                  {formik.errors.admin_sandboxes_type_id && formik.touched.admin_sandboxes_type_id ? (
                    <Select
                      labelId="admin_sandboxes_type_id"
                      id="admin_sandboxes_type_id"
                      labelWidth={100}
                      style={{ width: "100%" }}
                      name="admin_sandboxes_type_id"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      native
                      variant="filled"
                      value={formik.values.admin_sandboxes_type_id}
                      helperText={formik.errors.admin_sandboxes_type_id}
                      error={formik.errors.admin_sandboxes_type_id && formik.touched.admin_sandboxes_type_id
                        ? true
                        : false}
                    >
                        <option value="">Select Type</option>
                        {sandboxTypesData.map(data => <option value={data.id}>{data.sandboxes_type_name}</option>
                        )}
                      </Select>

                    
                  ) : (
                    <Select
                      labelId="admin_sandboxes_type_id"
                      id="admin_sandboxes_type_id"
                      labelWidth={100}
                      style={{marginTop:"15px", width: "100%"}}
                      name="admin_sandboxes_type_id"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      native
                      variant="filled"
                      value={formik.values.admin_sandboxes_type_id}
                      helperText={formik.errors.admin_sandboxes_type_id}
                      error={
                          formik.errors.admin_sandboxes_type_id && formik.touched.admin_sandboxes_type_id
                          ? true
                          : false
                      }
                  >
                  <option value="">Select Type</option>
                    {sandboxTypesData.map(data =>  
                        <option value={data.id}>{data.sandboxes_type_name}</option> 
                    )}   
                  </Select> 
                  )}
                </GridItem>
                <GridItem md={9} >
                  {formik.errors.price && formik.touched.price ? (
                    <TextField
                      type="text"
                      variant="filled"
                      label="Price"
                      name="price"
                      fullWidth
                      InputLabelProps={inputProps}
                      value={formik.values.price}
                      onChange={formik.handleChange}
                      helperText={formik.errors.price}
                      onBlur={formik.handleBlur}
                      error
                    />
                  ) : (
                    <TextField
                      type="text"
                      variant="filled"
                      label="Price"
                      name="price"
                      fullWidth
                      InputLabelProps={inputProps}
                      value={formik.values.price}
                      onChange={formik.handleChange}
                    />
                  )}
                </GridItem>

                <GridItem md={9} >
                  {formik.errors.price_unit && formik.touched.price_unit ? (
                    <TextField
                      type="text"
                      variant="filled"
                      label="Price Unit"
                      name="price_unit"
                      fullWidth
                      InputLabelProps={inputProps}
                      value={formik.values.price_unit}
                      onChange={formik.handleChange}
                      helperText={formik.errors.price_unit}
                      onBlur={formik.handleBlur}
                      error
                    />
                  ) : (
                    <TextField
                      type="text"
                      variant="filled"
                      label="Price Unit"
                      name="price_unit"
                      fullWidth
                      InputLabelProps={inputProps}
                      value={formik.values.price_unit}
                      onChange={formik.handleChange}
                    />
                  )}
                </GridItem>

                <GridItem md={9} >
                <p>Description</p>
                      <Editor
                ref={mdEditor}
                value={description}
                style={{
                  height: "500px"
                }}
                onChange={handleEditorChange}
                renderHTML={text => <ReactMarkdown source={text} />}
              />
                </GridItem>
                
                <GridItem md={9}>
                <TextField
                      type="text"
                      variant="filled"
                      label="Email Content"
                      name="email_content"
                      fullWidth
                      InputLabelProps={inputProps}
                      value={formik.values.email_content}
                      onChange={formik.handleChange}
                      multiline
                      rows={8}
                    />
                 </GridItem>

                <GridItem md={9} >
                  {formik.errors.diagram && formik.touched.diagram ? (
                    <TextField
                      type="text"
                      variant="filled"
                      label="Diagram"
                      name="diagram"
                      fullWidth
                      InputLabelProps={inputProps}
                      value={formik.values.diagram}
                      onChange={formik.handleChange}
                      helperText={formik.errors.diagram}
                      onBlur={formik.handleBlur}
                      error
                    />
                  ) : (
                    <TextField
                      type="text"
                      variant="filled"
                      label="Diagram"
                      name="diagram"
                      fullWidth
                      InputLabelProps={inputProps}
                      value={formik.values.diagram}
                      onChange={formik.handleChange}
                    />
                  )}
                </GridItem>

                <GridItem md={9} >
                  {formik.errors.diagram_img && formik.touched.diagram_img ? (
                    <TextField
                      type="text"
                      variant="filled"
                      label="Diagram Img"
                      name="diagram_img"
                      fullWidth
                      InputLabelProps={inputProps}
                      value={formik.values.firstName}
                      onChange={formik.handleChange}
                      helperText={formik.errors.firstName}
                      onBlur={formik.handleBlur}
                      error
                    />
                  ) : (
                    <TextField
                      type="text"
                      variant="filled"
                      label="Diagram Img"
                      name="diagram_img"
                      fullWidth
                      InputLabelProps={inputProps}
                      value={formik.values.diagram_img}
                      onChange={formik.handleChange}
                    />
                  )}
                </GridItem>

                <GridItem md={9} >
                  {formik.errors.icon_image && formik.touched.icon_image ? (
                    <TextField
                      type="text"
                      variant="filled"
                      label="Icon Image"
                      name="icon_image"
                      fullWidth
                      InputLabelProps={inputProps}
                      value={formik.values.icon_image}
                      onChange={formik.handleChange}
                      helperText={formik.errors.icon_image}
                      onBlur={formik.handleBlur}
                      error
                    />
                  ) : (
                    <TextField
                      type="text"
                      variant="filled"
                      label="Icon Image"
                      name="icon_image"
                      fullWidth
                      InputLabelProps={inputProps}
                      value={formik.values.icon_image}
                      onChange={formik.handleChange}
                    />
                  )}
                </GridItem>

                <GridItem md={9}>
                <Grid item xs={12} sm={12} md={12}>
                   <span>Is Enabled</span>
                   <FormControlLabel
                            control={<Checkbox checked={formik.values.is_enabled} />} 
                            name="is_enabled"
                            onChange={formik.handleChange}
                            />

                  < span>Access Impairment</span>
                  <FormControlLabel
                            control={<Checkbox checked={formik.values.access_impairment} />}
                            name="access_impairment"
                            onChange={formik.handleChange}
                            />

                  < span>Access Tcpreplay</span>
                  <FormControlLabel
                            control={<Checkbox checked={formik.values.access_tcpreplay} />}
                            name="access_tcpreplay"
                            onChange={formik.handleChange}
                            />
                  </Grid>
                </GridItem>


              </Grid>
              <Grid
                container
                justify="center"
                alignItems="center"
                style={{ marginTop: "20px", marginBottom: "30px" }}
              >
                <GridItem xs={12} sm={6} md={3} lg={3}>
                  {!btnDisabled ? (
                    <Button
                      variant="contained"
                      color="secondary"
                      disableElevation
                      size="large"
                      className={classes.buttStyle}
                      fullWidth
                      disableFocusRipple
                      type="submit"
                    >
                      Submit
                    </Button>
                  ) : null}
                  {btnDisabled ? (
                    <Loader
                      type={LOADER_TYPE}
                      color={theme.palette.secondary.main}
                      width={50}
                      height={50}
                      style={{ float: "right", marginRight: "15px" }}
                    />
                  ) : null}
                </GridItem>
                <GridItem xs={12} sm={6} md={3} lg={3}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    disableElevation
                    size="large"
                    // className={classes.buttStyle}
                    fullWidth
                    onClick={Cancelbutton}
                    disableFocusRipple
                    type="submit"
                  >
                    Cancel
                  </Button>
                </GridItem>
              </Grid>

              <NotificationAlert
                notificationType={notificationType}
                showNotification={showNotification}
                message={notificationMessage}
              />
            </form>
          </Grid>
        </Paper>
      </LoaderHoc>
    </div>
  );
};

export default AddNewSandbox;
