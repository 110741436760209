import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

// Material UI Components
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { SHOW_NOTIFICATION } from "action_creators/actioncreator";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Typography } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

// Local
import Menu from "../../Components/Menu";
import { SDCloudBackendAPIWithToken } from "../../../apis/backendAPI";
import { CMSAPIWithToken } from "../../../apis/CMS";
import { useDispatch } from "react-redux";
import { SET_NAVBAR_TITLE } from "action_creators/actioncreator";
import SandboxView from "./SandboxView";
import SandboxTypes from "./ProductContentSandboxesTypes";
import SandboxUpdate from "./SandboxUpdate";
import SandboxCopyModal from "./SandboxCopyModal";

// Material UI Components
import { Alert } from "@material-ui/lab";
// Icons
import Visibility from "@material-ui/icons/Visibility";
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import ForwardIcon from '@material-ui/icons/Forward';
// Local
import CustomTable from "../Components/Table";
import RightDrawer from "../Components/RightDrawer";
import LoaderHoc from "../../../components/HOC/LoaderHoc";
import { IconButton } from "@material-ui/core";
//import Visibility from "@material-ui/icons/Visibility";
import ViewInventory from "../../InventoryManagement/components/ViewInventory"
import AddInventory from "../../InventoryManagement/components/AddInventory"
import {hotjar} from "react-hotjar";


// Styling
const useStyles = makeStyles(theme => ({
    root: {
        marginTop: "50px"
    },
    backButton: {
      margin: "15px 0",
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
      "& svg": {
        marginRight: "5px",
      },
      "&:hover": {
        color: theme.palette.secondary.main,
        "& svg": {
          fill: theme.palette.secondary.main,
        },
      },
    },
}));

const ProductContentSandboxes = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const profileData = JSON.parse(localStorage.getItem("profileData"));
    const account = profileData.account;
    let domain = JSON.parse(localStorage.getItem("internal_domains"))?.domains;
    let userEmail = JSON.parse(localStorage.getItem("profileData"))?.email;
    let hasDomain = domain?.some((res) => userEmail.includes(res));
    const [isInternal, setIsInternal] = useState(hasDomain);
    useEffect(() => {
      dispatch(SET_NAVBAR_TITLE(isInternal?account+"  - Content Management Sandboxes":"Content Management Sandboxes"));
        renderrow();
        renderRowLearning();
    }, []);
    hotjar.initialize(2281731, 6);
    hotjar.identify(localStorage.userName, { userProperty: localStorage.userName});
    // States
    const [learningIdentifiers, setLearningIdentifiers] = useState([]); // Initialize as empty array
    const [sandboxIdentities, setSandboxIdentities] = useState([]);
    const [sandboxesRows, setSandboxesRows] = useState([]);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [currentInventory, setCurrentInventory] = useState("");
    const [showNotification, setShowNotification] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState([]);
    const [currentScreen, setCurrentScreen] = useState("Sandboxes");
    const [currentDrawerScreen, setcurrentDrawerScreen] = useState();
    const [isModalOpen, setIsModalOpen] = useState(false);
    
    const [currentUser, setCurrentUser] = useState();
    var sandbox_id_title_map = {};
    const [notificationType, setNotificationType] = useState("");
    const columns = [
      {
        Header: "Title",
        accessor: "title",
      },
      {
        Header: "Identity",
        accessor: "identity",
      },
      {
        Header: "Actions",
        accessor: "actions",
      },
    ];
  
    let sandboxesData = [];
    let identitys = [];
    let identifiers = [];
    //var menuItems = ["Sandboxes", "Sandbox Options", "Sandbox Details", "Sandboxes Cheat sheets", "Sandboxes Labs", "Sandboxes Categories","Sandboxes Exercises"];

    const [isLoading, setIsLoading] = useState(true);
    const renderNotififications = () => {
        let notifications = notificationMessage.map((item) => {
          let notificationType = item.success ? "success" : "error";
          return (
            <Alert
              onClose={() => setShowNotification(false)}
              severity={notificationType}
              spacing={1}
            >
              {item.message}
            </Alert>
          );
        });
    
        return notifications;
      };

      const renderrow = async (value) => {
        return CMSAPIWithToken()
        .get("/poc/admin_sandboxes/")
        .then((resp) => resp.data.results)
          .then((resp) => {
            resp.forEach((item) => {
              sandbox_id_title_map[item.id] = item.title;
                sandboxesData.push({
                  id: item.id,
                title: item.title,
                subtitle: item.subtitle,
                identity: item.identity,
                actions: (
                  <Grid container wrap="wrap" spacing={1}>
                    <IconButton onClick={() => handleViewButton(item.id)}>
                      <Visibility />
                    </IconButton>
                    <IconButton onClick={() => handleEditButton(item.id)}>
                      <EditIcon/>
                    </IconButton>
                    <IconButton onClick={() => handleDeleteButton(item.id)}>
                      <DeleteForeverIcon/>
                    </IconButton>
                    <IconButton onClick={() => handleForwardButton(item.id,item.title)}>
                      <ForwardIcon/>
                    </IconButton>
                  </Grid>
                ),
                });
                identitys.push(item.identity);
            });
            localStorage.removeItem("sandbox_id_title_map");
            localStorage.setItem("sandbox_id_title_map", JSON.stringify(sandbox_id_title_map));
            setSandboxesRows(sandboxesData);
            setSandboxIdentities(identitys);
            setIsLoading(false);
          })
          .catch((err) => {
            let message = err.response.data.message || "unknown error occured";
            setIsLoading(false);
          });
      };

      const renderRowLearning = async (value) => {
        return CMSAPIWithToken()
          .get("/learnings/admin_learnings/")
          .then((resp) => resp.data.results)
          .then((resp) => {
            resp.forEach((item) => {
              identifiers.push(item.identifier);
            });
            setLearningIdentifiers(identifiers);
            setIsLoading(false);
          })
          .catch((err) => {
            let message = err.response.data.message || "unknown error occurred";
            setIsLoading(false);
          });
      };

//Sandbox Delete Items 
      const handleDeleteButton = (id) => {
      
        let user_confirm = window.confirm("Do you want to delete excercise?")
        if(user_confirm){
        let url="/poc/admin_sandboxes"+`/${id}/`;
        CMSAPIWithToken()
        .delete("/poc/admin_sandboxes"+`/${id}/`)
        .then((resp) => resp.data.results)
        .then((data) => {
          const payload = {
              message: "Sandbox Deleted Successfully",
              color: "success",
          };
          payload.message && dispatch(SHOW_NOTIFICATION({ payload }));   
          history.push("/product-admin-sandboxes");
          window.location.reload()
      })
      .catch((err) => {
        let message = err.response.data.message;
        const payload = {
          message: message,
          color: "danger",
      };
      payload.message && dispatch(SHOW_NOTIFICATION({ payload })); 
      });
      };
    }
      // view single product details
      const renderDrawerComponent = () => {
        switch (currentDrawerScreen) {
          case "Sandboxes View":
            return <SandboxView user={currentUser} />;
          case "Sandboxes Edit":
            return <SandboxUpdate user={currentUser} />;
                            
          case "default":
            return null;
        }
      };
      //code for view button
      const handleViewButton = (username) => {
        setOpenDrawer(true);
        setcurrentDrawerScreen("Sandboxes View");
        setCurrentUser(username);
      };

      const handleEditButton = (username) => {
        setOpenDrawer(true);
        setcurrentDrawerScreen("Sandboxes Edit");
        setCurrentUser(username);
      };

      const handleForwardButton = (sb_id,title) => {
        history.push("ProductContentSandboxes/ProductContentSandboxesMenuItems?id="+sb_id+"&title="+title);
      };

      //Add new sandbox , Option , Details ,Cheat Sheets, Labs ,Categories,Exercises
      const handleAddUserSandbox = () => {
        history.push("ProductContentSandboxes/AddNewSandbox");  
      };

      const handleContentCopy = () => {
        setIsModalOpen(true);
        //console.log("trueee")
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

      const backButton = () => {
        history.goBack();
      }
      
    const renderContent = () => {
        return (
          <Grid container className={classes.root} spacing={1}>
            <Grid item xs={12} md={12}>
              {showNotification ? renderNotififications() : null}
            </Grid>
            <Grid item xs={12} md={12}>
              <LoaderHoc isLoading={isLoading}>
                <CustomTable
                  columns={columns}
                  data={sandboxesRows}
                  handleDeleteButtonClick={handleDeleteButton}
                  handleButtonClick={handleAddUserSandbox}
                  handleViewButtonClick={handleViewButton}
                  tableTitle="Sandboxes"
                  buttonTitle="Add New Sandboxes"
                  copycontent="Content Copy"
                  handleContentCopyClick={handleContentCopy}
                  hiddenColumns={[]}
                  contentType="sandbox"
                />
              </LoaderHoc>
            </Grid>
          </Grid>
        );
    };
    return (
        <div>
            <Grid container className={classes.root}>
            <GridContainer className="backbutton">
                <GridItem sm={12} md={12}>
                  <Typography
                    variant="body1"
                    className={classes.backButton}
                    onClick={backButton}
                  >
                    <ArrowBackIcon /> Back
                  </Typography>
                </GridItem>
              </GridContainer>
                <Grid item md={12}>
                    {renderContent()}
                </Grid>
                <Grid item md={12}>
                    <SandboxTypes/>
                </Grid>
                 {/* Drawer */}
                 <RightDrawer
                      open={openDrawer}
                      onClose={setOpenDrawer}
                      title={currentInventory}
                    >
                      {renderDrawerComponent()}
                    </RightDrawer>
                    <SandboxCopyModal
                      open={isModalOpen}
                      handleClose={handleCloseModal}
                      sandboxIdentifiers={sandboxIdentities}
                      targetIdentifiers={learningIdentifiers}
                    />
            </Grid>
        </div>
    );
    
};

export default ProductContentSandboxes;