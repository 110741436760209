import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

// Redux
import { useDispatch, useSelector } from "react-redux";
import {
  SHOW_LOADER,
  HIDE_LOADER,
  SET_NAVBAR_TITLE,
  SHOW_NOTIFICATION
} from "action_creators/actioncreator";

// Material UI Components
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

// Local
// Local
// import { Api, Offerings } from "../../../../../criterion_sdcloud_ui/src/config/api";
// import Menu from "../../../../../criterion_sdcloud_ui/src/views/Components/Menu";
import TemplateCards from "./Sandboxes/TemplateCards";
// import { SDCloudBackendAPIWithToken } from "../../../../../criterion_sdcloud_ui/src/apis/backendAPI";
// import { CMSAPIWithToken } from "../../../../../criterion_sdcloud_ui/src/apis/CMS";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import {
  getResourcesStatus,
  getSandboxesCluster,
  getServiceStatus
} from "../../../apis/APIServices";
import globalTheme from "../../../theme";
import { msToStr } from "views/ServiceOffering/LearningLab/acess labs/sdntokenizerhelper";
import moment from "moment";
import { calcTimeLeft } from "../../../utils/utilfunction";
import { CMSAPIWithToken } from "apis/CMS";
import { SDCloudBackendAPIWithToken } from "apis/backendAPI";
import { Api } from "config/api";
import ExperienceCenterUpgrade from "views/Components/ExperienceCenterUpgrade";
import unlock from "assets/img/unlock.png";
import Button from "@material-ui/core/Button";
import ScheduleCluster from "../../../views/ScheduleCluster/ScheduleCluster";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { Offerings } from "config/api";
import { ReactComponent as svg7 } from "../../../assets/criterion_networks/svg/07.svg";
import SvgComponent from "utils/SvgComponent";
import { ReactComponent as svg11 } from "../../../assets/criterion_networks/svg/11.svg";
import Loader from "react-loader-spinner";
import { LOADER_TYPE } from "global_constants/app_constants";
import Chatbot from "views/Components/chatbot/Chatbot";
const useStyles = makeStyles(theme => ({
  root: {
    marginTop: "50px"
  },
  button: {
    padding: 0,
    margin: 0,
    alignSelf: "flex-end",
    fontSize: "15px",
    textDecorationLine: "underline",
    float: "right",
    marginTop: "48px"
  },
  colour: {
    color: `${theme.palette.secondary.main} !important`
  },
  customTabRoot: {
    color: `#000`,
    "& .Mui-selected .MuiTab-wrapper": {
      color: `${theme.palette.secondary.main}`
    },
    "& .MuiTab-wrapper": {
      fontFamily: theme.palette.fontList.selectedFont
    }
  },
  imgFilter: {
    filter: theme.palette.secondary.filterImage,
    background: "transparent !important"
  },
  font: {
    fontFamily: theme.palette.fontList.selectedFont
  },
  unsetIcon: {
    fontSize: "unset",
    overflow: "unset"
  },
  absoluteImg: {
    // position: "absolute",
    height: "114px",
    width: "114px",
    left: "56px",
    borderRadius: "0px",
    top: "28px",
    background: "black"
  }
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={4}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

//   const useStyles = makeStyles((theme) => ({
//     root: {
//       flexGrow: 1,
//       backgroundColor: theme.palette.background.paper,
//     },
//   }));

export default function SandboxTemplates(props) {
  const [cardData, setCardData] = useState([]);
  const [searchfilter, setSearchFilter] = useState([]);
  let currentTabScreen;
  if (JSON.parse(localStorage.getItem("profileData")).privilege === "admin") {
    if (props.location.state && props.location.state.currentTab) {
      currentTabScreen =
        props.location.state.currentTab === "Active" 
          ? "Active" 
          : props.location.state.currentTab === "Inactive" 
          ? "Inactive"
          : "All";
    }
  } else {
    if (props.location.state && props.location.state.currentTab) {
      currentTabScreen =
        props.location.state.currentTab === "Active"
          ? "Active"
          : props.location.state.currentTab === "Inactive"
          ? "Inactive"
          : props.location.state.currentTab === "Subscribed"
          ? "Subscribed"
          : "All";
    }
  }
  const [currentScreen, setCurrentScreen] = useState(currentTabScreen);
  const [reservedIP, setReservedIP] = useState({});
  const [reservedIPLoaded, setReservedIPLoaded] = useState(false);
  const [runningClusters, setRunningClusters] = useState([]);
  const [subscribedList, setsubscribedList] = useState([]);
  const [subscribedSearchfilter, setsubscribedSearchFilter] = useState([]);
  const [activeList, setactiveList] = useState({});
  const [activeListTemp, setactiveListTemp] = useState({});
  const [inActiveList, setinactiveList] = useState({});
  const [inActiveListTemp, setinActiveListTemp] = useState({});
  const [activeLab, setactiveLab] = useState(false);
  const [currentLabType, setcurrentLabType] = useState("Active");
  const [sbTypes, setSbTypes] = useState([]);
  const [sbIDToNameMap, setSbIDToNameMap] = useState([]); 
  const [selectedRows, setselectedRows] = useState([]);
  const [resources, setResources] = useState([]);
  const [services, setServices] = useState([]);
  const [app_bar, setapp_bar] = useState(false);
  const [sandboxInventoryData, setSandboxInventoryData] = useState({});
  const profileData = JSON.parse(localStorage.getItem("profileData"));
  const account = profileData.account;
  let domain = JSON.parse(localStorage.getItem("internal_domains"))?.domains;
  let userEmail = JSON.parse(localStorage.getItem("profileData"))?.email;
  let hasDomain = domain?.some((res) => userEmail.includes(res));
  const [isInternal, setIsInternal] = useState(hasDomain);
  const templateFilters = ["All", "Recent"];

  const classes = useStyles();
  const dispatch = useDispatch();
  //  let { profileObj } = useSelector((state) => state);
  let currentTabIndex;
  if (JSON.parse(localStorage.getItem("profileData")).privilege === "admin") {
    currentTabIndex = 0;
    currentTabScreen = "All";
  } else {
    currentTabIndex = 1;
    currentTabScreen = "Subscribed";
  }
  if (JSON.parse(localStorage.getItem("profileData")).privilege === "admin") {
    if (props.location.state && props.location.state.currentTab) {
      currentTabIndex = 
        props.location.state.currentTab === "Active" 
        ? 1 
        : props.location.state.currentTab === "Inactive"
        ? 2 
        : 0;
    }
  } else {
    if (props.location.state && props.location.state.currentTab) {
      currentTabIndex =
        props.location.state.currentTab === "Active"
          ? 2
          : props.location.state.currentTab === "Inactive"
          ? 3
          : props.location.state.currentTab === "Subscribed"
          ? 1
          : 0;
    }
  }
  const [value, setValue] = React.useState(currentTabIndex);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const menuItems = ["All", "Recents"];

  let sections = [
    {
      name: "Designer Templates",
      id: "designer",
      ScreenName: "Design",
      Upgrade: "Design Center"
    },
    {
      name: "Learning labs",
      id: "learning",
      ScreenName: "Learning",
      Upgrade: "Learning Center"
    },
    {
      name: "Experience Center - Showcases",
      id: "sales_demo",
      tempId: "showcases",
      ScreenName: "Showcases",
      Upgrade: "Experience Center"
    },
    {
      name: "Experience Center - Sandboxes",
      id: "poc",
      tempId: "sandboxes",
      ScreenName: "Sandboxes",
      Upgrade: "Experience Center"
    }
  ];

  // get reserved IP settings for user, account and offering specific
  const getReservedIp = () => {
    dispatch(SHOW_LOADER());
    return SDCloudBackendAPIWithToken()
      .get(Api.getUserProfile)
      .then(res => {
        let reservedIpObj = {};
        if (res.data.static_ips?.length) {
          reservedIpObj["reservedIps"] = [...res.data.static_ips];
          reservedIpObj["ipService"] = true;
        }
        return reservedIpObj;
      })
      .then(reservedIpObj => {
        return SDCloudBackendAPIWithToken()
          .get(Api.getAccountDetails)
          .then(res => {
            if (res.data.reserved_ip) {
              reservedIpObj["staticIp"] = res.data.reserved_ip;
            }
            if (res.data.ip_available_services?.includes("poc")) {
              reservedIpObj["ipService"] = true;
            } else {
              reservedIpObj["ipService"] = false;
            }
            return reservedIpObj;
          });
      })
      .then(reservedIpObj => {
        if (
          reservedIpObj.ipService &&
          (reservedIpObj.reservedIps || reservedIpObj.staticIp)
        ) {
          reservedIpObj["dynamicIp"] = "default";

          setReservedIP({ ...reservedIpObj });
          setReservedIPLoaded(true);
        } else {
          setReservedIPLoaded(true);
        }
      })
      .catch(err => {
        // const payload = {
        //   message: err?.response?.data?.message || "unknown error occured",
        //   color: "danger",
        //   error: err
        // };
        // dispatch(SHOW_NOTIFICATION({ payload }));
      });
  };

  const getSandboxTypes = () => {
    const labIdMapArr = []
    let response = CMSAPIWithToken().get(Api.adminSandboxesTypes).then((res) => {
      setSbTypes(res.data.results) 
    })   
  };

  const getRunningClusters = () => {
    dispatch(SHOW_LOADER());
    SDCloudBackendAPIWithToken()
      .get(Api.getClusterlist)
      .then(resp => resp.data)
      .then(data => {
        setRunningClusters(data);
      })
      .catch(err => {});
  };

  const getActiveSandboxes = async () => {
    // dispatch(SHOW_LOADER());
    const res = await getSandboxesCluster();
    if (!res.success) {
      // const payload = {
      //   message: res.msg || "unknown error occured",
      //   color: "danger"
      // };
      // dispatch(SHOW_NOTIFICATION({ payload }));
      // dispatch(HIDE_LOADER());
    } else {
      const clouds = res.data.clouds;
      let response = await CMSAPIWithToken().get(Api.adminSandboxes, {
        params: {
          limit: "1000"
        }
      });
      let cmsdata = response.data.results;
      var result = clouds.filter(o1 =>
        cmsdata.map(o2 => o1.template_name === o2.identity)
      );
      const activeLabs = result.filter(
        item => !item.deleted && item.uuid && item.status !== "suspended" && item.status !== "archived"
      );
      const inactiveLabs = result.filter(
        item => !item.deleted && item.status === "suspended" || item.status === "archived"
      );
      let updatedState = {
        activeLabs,
        allLabs: activeLabs.reverse().concat(inactiveLabs.reverse())
      };
      let newState = {
        inactiveLabs,
        allLabs: activeLabs.reverse().concat(inactiveLabs.reverse())
      };
      if (activeLabs.length === 0) {
        updatedState = { ...updatedState, show: true };
      } else {
        updatedState = { ...updatedState, show: false, learn: clouds };
      }
      if (inactiveLabs.length === 0) {
        newState = { ...newState, show: true };
      } else {
        newState = { ...newState, show: false, learn: clouds };
      }
      setactiveList(updatedState.activeLabs);
      setactiveListTemp(updatedState);
      setinactiveList(newState.inactiveLabs);
      setinActiveListTemp(newState);
      //   dispatch(HIDE_LOADER());
    }
  };

  useEffect(() => {
    dispatch(SHOW_LOADER());
    dispatch(SET_NAVBAR_TITLE(isInternal?account+"  - Experience Center - Sandboxes": "Experience Center - Sandboxes"));
    if (currentTabScreen === "Active") {
      setactiveLab(true);
    }

    const getTemplateData = async () => {
      dispatch(SHOW_LOADER());
      let response = await CMSAPIWithToken().get(Api.adminSandboxes, {
        params: {
          limit: "1000"
        }
      });
      let cmsData = response.data.results;
      response = await SDCloudBackendAPIWithToken().get(Api.getResource_sandboxes);
      let resources = response.data.sandboxes;

      cmsData = cmsData.filter(item => {
        let temp = resources.some(data => {
          if (data.name === item.identity) {
            item["profileData"] = { ...data };
          }
          return data.name === item.identity;
        });
        return temp;
      });

      if (
        JSON.parse(localStorage.getItem("profileData")).privilege ===
          "developer" ||
        JSON.parse(localStorage.getItem("profileData")).privilege ===
          "profile_admin"
      ) {
        response = await SDCloudBackendAPIWithToken().get(Api.getProfile);
        let profile = response.data.profiles[0].permissions.filter(item => {
          return item.resource_type === "sandbox";
        });

        cmsData.forEach(item => {
          let temp = profile.some(data => {
            if (data.resource_name === item.identity) {
              item["profileData"] = { ...data };
              item["access"] = true;
            }
          });
          return temp;
        });
      }

      cmsData.sort(function(a, b) {
        var textA = a.access;
        return textA ? -1 : 1;
      });

      let SubscribedList = cmsData.filter(item => {
        let isLabDisabled = false;
        if (item.profileData.permission) {
          isLabDisabled = item.profileData.permission === "delete";
        }
        if (item.access && !isLabDisabled) {
          return item;
        }
      });
      setsubscribedList(SubscribedList);
      setsubscribedSearchFilter(SubscribedList);

      setCardData(cmsData);
      setSearchFilter(cmsData);

      await Promise.all([
        getReservedIp(),
        getRunningClusters(),
        getActiveSandboxes(),
        getInventory(),
        getResources(),
        getSandboxTypes()
      ]);
      dispatch(HIDE_LOADER());
      setapp_bar(true);
    };

    getTemplateData();
  }, []);

  const handleHeaderClick = (selectedLabType, type) => {
    if (selectedLabType == "All") {
      const searchList2 =
        activeListTemp.allLabs && activeListTemp.allLabs.length
          ? activeListTemp.allLabs.filter(obj =>
              ["clustername"].some(key => {
                return (
                  obj[key] &&
                  obj[key].toLowerCase().includes(searchText.toLowerCase())
                );
              })
            )
          : activeListTemp.allLabs;

      setactiveList(searchList2);
      setcurrentLabType("All");
    } 
    else if (selectedLabType == "Active") {
      const searchList2 =
        activeListTemp.activeLabs && activeListTemp.activeLabs.length
          ? activeListTemp.activeLabs.filter(obj =>
              ["clustername"].some(key => {
                return (
                  obj[key] &&
                  obj[key].toLowerCase().includes(searchText.toLowerCase())
                );
              })
            )
          : activeListTemp.activeLabs;
      setactiveList(searchList2);
      setcurrentLabType("Active");
    } else if (selectedLabType == "Inactive"){
      const searchList3 =
      inActiveListTemp.inactiveLabs && inActiveListTemp.inactiveLabs.length
          ? inActiveListTemp.inactiveLabs.filter(obj =>
              ["clustername"].some(key => {
                return (
                  obj[key] &&
                  obj[key].toLowerCase().includes(searchText.toLowerCase())
                );
              })
            )
          : inActiveListTemp.inactiveLabs;
      setinactiveList(searchList3);
      setcurrentLabType("InActive");
    }
  };

  const getResources = async () => {
    // dispatch(SHOW_LOADER());
    const res = await getResourcesStatus();
    if (!res.success) {
      //   dispatch(SHOW_NOTIFICATION({ payload: res.msg || errPayload }));
      return;
    }
    const resources = res.data;

    sections = sections.map(item => ({
      available:
        (resources[item.id] &&
          resources[item.id].allocated_hours &&
          resources[item.id].allocated_hours -
            resources[item.id].consumed_hours) ||
        0,
      isLaunch: !!resources[item.id],
      ...item
    }));
    setResources(() => sections);
    getServices();
  };

  const getInventory = async () => {
    let inventoryData = {};
    SDCloudBackendAPIWithToken()
      .get(`/inventory/view_inventories/`)
      .then(resp => resp.data.inventories)
      .then(resp => {
        resp.forEach(item => {
          if (item.inventory_type == "Sandbox") {
            inventoryData[item.sku_name] = item.tags;
          }
          setSandboxInventoryData(inventoryData);
        });
      });
  };

  const getServices = async () => {
    const res = await getServiceStatus();
    if (!res.success) {
      return;
    }
    setServices(res.data);
  };

  
  const renderTemplateCards = () => {
    // This will sort the cards on the basis of sandbox permission
    cardData.sort((value1, value2) => {
      if (value1.profileData.permission === "view") {
        return 1;
      } else {
        return 0;
      }
    });

    const cards = cardData.map(item => {
      return (
        <TemplateCards
          cardData={item}
          key={uuidv4()}
          reservedIP={reservedIP}
          runningClusters={runningClusters}
          inventoryData={sandboxInventoryData}
        />
      );
    });

    return cards;
  };

  const renderSubscribedCards = () => {
    // This will sort the cards on the basis of sandbox permission
    // cardData.sort((value1, value2) => {
    //
    //     if (value1.profileData.permission === "view") {
    //         return 1;
    //     } else {
    //         return 0;
    //     }
    // });
    const cards = subscribedList.map(item => {
      return (
        <TemplateCards
          cardData={item}
          key={uuidv4()}
          reservedIP={reservedIP}
          runningClusters={runningClusters}
        />
      );
    });

    return cards;
  };

  const handleGotoLab = item => {
    props.history.push(`/access-sandbox/${item.uuid}/${item.template_name}`);
  };
  
  const renderSideLabMenu = () => {
    const labType = new Set();
    console.log(typeof sbIDToNameMap);

    return (
      <div className="search-container-right" style={{width:"70%"}}>
        <a
          href="#"
          onClick={() => handleHeaderClick("All", "subitem")}
          className={classes.font}
          style={
            true
              ? selectedlabTypeStyle
              : {}
          }
        >
          All
        </a>
        {Array.from(sbTypes).map((item) => {
          return (
            <a
              href="#"
              onClick={() => handleHeaderClick(item.type_name, "subitem")}
              className={classes.font}
              style={
                false
                  ? selectedlabTypeStyle
                  : {}
              }
            >
              {item.type_name}
            </a>
          );
        })}
      </div>
    );
  }
  const renderActiveCards = () => {
    return (
      <>
        {activeList?.length > 0 &&
          activeList.map((item, key) => {
            let sandboxRuntime = null;
            let sandboxRuntime1 = null;
            let sandboxRuntime2 = null;
            if (!item.timeStamp) {
              if (!item.end_time) {
                sandboxRuntime1 = msToStr(
                  60 * 60 * item.suspended_hours,
                  moment(new Date()).format("YYYY-MM-DD HH:mm")
                );
              } else {
                sandboxRuntime1 = moment(item.end_time).format(
                  "YYYY-MM-DD HH:mm"
                );
              }
            } else {
              sandboxRuntime1 = moment(item.timeStamp).format(
                "YYYY-MM-DD HH:mm"
              );
            }

            if (!item.end_time) {
              sandboxRuntime2 = msToStr(
                60 * 60 * item.suspended_hours,
                moment(new Date()).format("YYYY-MM-DD HH:mm")
              );
            } else {
              sandboxRuntime2 = moment(new Date()).format("YYYY-MM-DD HH:mm");
              if(item.suspended_hours != null || item.suspended_hours != undefined) {
                // sandboxRuntime2 = moment(sandboxRuntime2).subtract((60 * item.suspended_hours), "minutes").format("YYYY-MM-DD HH:mm")
                var d = new Date()
                d.setMinutes(d.getMinutes() - (item.suspended_hours*60));
                sandboxRuntime2 = moment(d).format("YYYY-MM-DD HH:mm")
              }
            }
            let endTime = moment(item.end_time).format("YYYY-MM-DD HH:mm");
            if(item.status == 'suspended' || item.status == 'archived'){
              let provisionTime = moment(item.timeStamp).format(
                "YYYY-MM-DD HH:mm"
              );
              sandboxRuntime = msToStr(provisionTime, sandboxRuntime2);
            } else if (moment(new Date()).format("YYYY-MM-DD HH:mm") > endTime) {
              let provisionTime = moment(item.timeStamp).format(
                "YYYY-MM-DD HH:mm"
              );
              sandboxRuntime = msToStr(provisionTime, endTime);
            } else {
              if (item.timeStamp) {
                let provisionTime = moment(item.timeStamp).format(
                  "YYYY-MM-DD HH:mm"
                );
                sandboxRuntime = msToStr(provisionTime, sandboxRuntime2);
              } else {
                sandboxRuntime = msToStr(sandboxRuntime2, sandboxRuntime2);
              }
            }

            let runtime = calcTimeLeft(sandboxRuntime, item.status);
            if(item.status =='resuming'){
              runtime = ' Fetching...'
            }
            let pocstatus = item.deleted == false ? !item.deleted : undefined;
            let status =
              pocstatus &&
              (item.status == "Not provisioned" ||
                (item.status !== "terminated" && item.status !== "suspended" && item.status !== "archived"))
                ? "Active"
                : "Inactive";
            const imageUrl = item.thumbnail ? item.thumbnail : "";
            return (
              <div className="sortable-moves">
                {imageUrl.trim() !== "" ? (
                  <img
                    className="img-fluid p-absolute image-dim"
                    src={imageUrl}
                    alt=""
                  ></img>
                ) : (
                  <div className="imgdefault">
                    <SvgComponent
                      className={classes.absoluteImg}
                      type="default"
                      svgSource={svg11}
                    />
                  </div>
                )}
                <h6 className={`card-title ${classes.font}`}>
                  {item.clustername}
                  <SvgComponent
                    className={`new-lock ${classes.unsetIcon}`}
                    type="icon"
                    svgSource={svg7}
                  />
                  {/* <img className={`new-lock ${classes.imgFilter}`} src={unlock} alt=''></img> */}
                </h6>
                <p className="card-content"></p>
                <Grid container spacing={0}>
                  <Grid item xs={12} sm={9}>
                    <p
                      style={{ width: "76%" }}
                      className={`card-content positionActive card-left card-sand-left ${classes.font}`}
                    >
                      Running time :<span>{runtime}</span>{" "}
                      <span className="sepbar">|</span> Status :{" "}
                      <span>{status}</span>
                      <br /> Lab Status :{" "}
                      <span>
                        {item.status.charAt(0).toUpperCase() +
                          item.status.slice(1)}
                      </span>
                      <span className="sepbar">|</span>Launched By :{" "}
                      <span>
                        {item.user_name.charAt(0).toUpperCase() +
                          item.user_name.slice(1)}
                      </span>
                    </p>
                  </Grid>
                  <Grid item xs={12} sm={3} justifyContent="flex-end">
                    <Button
                      color="secondary"
                      variant="text"
                      disableElevation
                      size="medium"
                      disableFocusRipple
                      type="submit"
                      className={classes.button + " " + classes.colour}
                      onClick={() => handleGotoLab(item)}
                      disabled={
                        ['terminated','terminating','under live edit process','Under Live Edit Process'].includes(item.status)
                          ? true
                          : false
                      }
                    >
                      Access Sandboxes
                    </Button>
                  </Grid>
                </Grid>
                {/* <p class="card-content positionActive card-right card-sand-right" style={{ cursor: "pointer" }}><a onClick={() => handleGotoLab(item)}> Goto Lab </a></p> */}
              </div>
            );
          })}
      </>
    );
  };
  const renderinActiveCards = () => {
    return (
      <>
        {inActiveList?.length > 0 &&
          inActiveList.map((item, key) => {
            let sandboxRuntime = null;
            let sandboxRuntime1 = null;
            let sandboxRuntime2 = null;
            if (!item.timeStamp) {
              if (!item.end_time) {
                sandboxRuntime1 = msToStr(
                  60 * 60 * item.suspended_hours,
                  moment(new Date()).format("YYYY-MM-DD HH:mm")
                );
              } else {
                sandboxRuntime1 = moment(item.end_time).format(
                  "YYYY-MM-DD HH:mm"
                );
              }
            } else {
              sandboxRuntime1 = moment(item.timeStamp).format(
                "YYYY-MM-DD HH:mm"
              );
            }

            if (!item.end_time) {
              sandboxRuntime2 = msToStr(
                60 * 60 * item.suspended_hours,
                moment(new Date()).format("YYYY-MM-DD HH:mm")
              );
            } else {
              sandboxRuntime2 = moment(new Date()).format("YYYY-MM-DD HH:mm");
              if(item.suspended_hours != null || item.suspended_hours != undefined) {
                // sandboxRuntime2 = moment(sandboxRuntime2).subtract((60 * item.suspended_hours), "minutes").toDate()
                var d = new Date()
                d.setMinutes(d.getMinutes() - (item.suspended_hours*60));
                sandboxRuntime2 = moment(d).format("YYYY-MM-DD HH:mm")
              }
            }
            let endTime = moment(item.end_time).format("YYYY-MM-DD HH:mm");
            if(item.status == 'suspended' || item.status == 'archived'){
              let provisionTime = moment(item.timeStamp).format(
                "YYYY-MM-DD HH:mm"
              );
              sandboxRuntime = msToStr(provisionTime, sandboxRuntime2); 
            } else if (moment(new Date()).format("YYYY-MM-DD HH:mm") > endTime) {
              let provisionTime = moment(item.timeStamp).format(
                "YYYY-MM-DD HH:mm"
              );
              sandboxRuntime = msToStr(provisionTime, endTime);
            } else {
              if (item.timeStamp) {
                let provisionTime = moment(item.timeStamp).format(
                  "YYYY-MM-DD HH:mm"
                );
                sandboxRuntime = msToStr(provisionTime, sandboxRuntime2);
              } else {
                sandboxRuntime = msToStr(sandboxRuntime2, sandboxRuntime2);
              }
            }

            let runtime = calcTimeLeft(sandboxRuntime, item.status);
            if(item.status =='resuming'){
              runtime = ' Fetching...'
            }
            let pocstatus = item.deleted == false ? !item.deleted : undefined;
            let status =
              pocstatus &&
              (item.status == "Not provisioned" ||
                (item.status !== "terminated" && item.status !== "suspended" && item.status !== "archived"))
                ? "Active"
                : "Inactive";
            const imageUrl = item.thumbnail ? item.thumbnail : "";
            return (
              <div className="sortable-moves">
                {imageUrl.trim() !== "" ? (
                  <img
                    className="img-fluid p-absolute image-dim"
                    src={imageUrl}
                    alt=""
                  ></img>
                ) : (
                  <div className="imgdefault">
                    <SvgComponent
                      className={classes.absoluteImg}
                      type="default"
                      svgSource={svg11}
                    />
                  </div>
                )}
                <h6 className={`card-title ${classes.font}`}>
                  {item.clustername}
                  <SvgComponent
                    className={`new-lock ${classes.unsetIcon}`}
                    type="icon"
                    svgSource={svg7}
                  />
                  {/* <img className={`new-lock ${classes.imgFilter}`} src={unlock} alt=''></img> */}
                </h6>
                <p className="card-content"></p>
                <Grid container spacing={0}>
                  <Grid item xs={12} sm={9}>
                    <p
                      style={{ width: "76%" }}
                      className={`card-content positionActive card-left card-sand-left ${classes.font}`}
                    >
                      Running time :<span>{runtime}</span>{" "}
                      <span className="sepbar">|</span> Status :{" "}
                      <span>{status}</span>
                      <br /> Lab Status :{" "}
                      <span>
                        {item.status.charAt(0).toUpperCase() +
                          item.status.slice(1)}
                      </span>
                      <span className="sepbar">|</span>Launched By :{" "}
                      <span>
                        {item.user_name.charAt(0).toUpperCase() +
                          item.user_name.slice(1)}
                      </span>
                    </p>
                  </Grid>
                  <Grid item xs={12} sm={3} justifyContent="flex-end">
                    <Button
                      color="secondary"
                      variant="text"
                      disableElevation
                      size="medium"
                      disableFocusRipple
                      type="submit"
                      className={classes.button + " " + classes.colour}
                      onClick={() => handleGotoLab(item)}
                      disabled={
                        item.status === "terminated" ||
                        item.status === "terminating"
                          ? true
                          : false
                      }
                    >
                      Access Sandboxes
                    </Button>
                  </Grid>
                </Grid>
                {/* <p class="card-content positionActive card-right card-sand-right" style={{ cursor: "pointer" }}><a onClick={() => handleGotoLab(item)}> Goto Lab </a></p> */}
              </div>
            );
          })}
      </>
    );
  };

  const [searchText, setSearchText] = useState("");
  const [CurrentTab, setCurrentTab] = useState("All");

  const handleChanges = e => {
    const { value } = e.target;
    let admin =
      JSON.parse(localStorage.getItem("profileData")).privilege === "admin";
    const searchList = searchfilter.filter(obj =>
      ["subtitle", "title"].some(key => {
        return obj[key] && obj[key].toLowerCase().includes(value.toLowerCase());
      })
    );
    const searchList1 = subscribedSearchfilter.filter(obj =>
      ["subtitle", "title"].some(key => {
        return obj[key] && obj[key].toLowerCase().includes(value.toLowerCase());
      })
    );
    if (currentLabType === "Active"){
      const searchList2 = activeListTemp.activeLabs.filter(obj =>
              admin
                ? ["clustername", "user_name"].some(key => {
                    return (
                      obj[key] &&
                      obj[key].toLowerCase().includes(value.toLowerCase())
                    );
                  })
                : ["clustername"].some(key => {
                    return (
                      obj[key] &&
                      obj[key].toLowerCase().includes(value.toLowerCase())
                    );
                  })
      );
      setactiveList(
        value !== ""
          ? searchList2
          : activeListTemp?.activeLabs
      );
    }
    if (currentLabType === "Inactive"){
      const searchList3 = inActiveListTemp.inactiveLabs.filter(obj =>
              admin
                ? ["clustername", "user_name"].some(key => {
                    return (
                      obj[key] &&
                      obj[key].toLowerCase().includes(value.toLowerCase())
                    );
                  })
                : ["clustername"].some(key => {
                    return (
                      obj[key] &&
                      obj[key].toLowerCase().includes(value.toLowerCase())
                    );
                  })
      );
      setinactiveList(
        value !== ""
          ? searchList3
          : inActiveListTemp?.inactiveLabs
      );
    }
    setSearchText(value);
    setsubscribedList(value !== "" ? searchList1 : subscribedSearchfilter);
    setCardData(value !== "" ? searchList : searchfilter);
  };

  const handleActiveTab = tab => {
    setCurrentTab(tab);
    if (tab == "Active") {
      setcurrentLabType("Active");
      setSearchText("");
      setactiveLab(true);
      setCurrentScreen("Active");
      setactiveList(activeListTemp.activeLabs);
    } else if (tab == "All") {
      setSearchText("");
      setactiveLab(false);
      setCurrentScreen("All");
      setCardData(searchfilter);
    } else if (tab == "Subscribed") {
      setSearchText("");
      setactiveLab(false);
      setCurrentScreen("Subscribed");
      setsubscribedList(subscribedSearchfilter);
    } else if (tab == "Schedule") {
      setSearchText("");
      setactiveLab(false);
      setCurrentScreen("Schedule");
      // setsubscribedList(subscribedSearchfilter);
    } else if (tab == "Inactive") {
      setcurrentLabType("Inactive");
      setSearchText("");
      setactiveLab(false);
      setCurrentScreen("Inactive");
      setinactiveList(inActiveListTemp.inactiveLabs);
    }
  };



  const selectedlabTypeStyle = {
    color: globalTheme.palette.secondary.main
  };

  const onRowSelect = selectedRows => {
    setselectedRows(selectedRows);
  };

  const getLabIdfromLabIdentifier = labIdentifier => {
    Api.adminSalesDemo(1000);
    return CMSAPIWithToken()
      .get(Api.adminSalesDemo(1000))
      .then(res => {
        const matchedLabFromCms = res.data.results.filter(item => {
          return item.identifier == labIdentifier;
        });
        return matchedLabFromCms[0];
      })
      .catch(err => {
        // const payload = {
        //   message: err?.response?.data?.message || "unknown error occured",
        //   color: "danger",
        //   error: err,
        //   showErrorPage: true
        // };
        // dispatch(SHOW_NOTIFICATION({ payload }));
      });
  };
  let currentStatus = resources.find(o => o.id == "poc");
  if (currentStatus && !currentStatus.isLaunch) {
    return <ExperienceCenterUpgrade page={"sandboxes"} />;
  } else {
    return (
      <>
        {app_bar ? (
          <div className={classes.root} id="sandbox-mt-0">
            {JSON.parse(localStorage.getItem("profileData")).privilege ===
            "admin" ? (
              <AppBar position="static">
                <Tabs
                  value={value}
                  classes={{
                    root: classes.customTabRoot
                  }}
                  onChange={handleChange}
                  aria-label="simple tabs example"
                >
                  <Tab
                    label="Catalog"
                    {...a11yProps(0)}
                    onClick={() => handleActiveTab("All")}
                  />
                  <Tab
                    label="Active"
                    {...a11yProps(1)}
                    onClick={() => handleActiveTab("Active")}
                  />
                  <Tab
                    label="Inactive"
                    {...a11yProps(2)}
                    onClick={() => handleActiveTab("Inactive")}
                  />
                  <Tab
                    label="Scheduled"
                    {...a11yProps(3)}
                    onClick={() => handleActiveTab("Schedule")}
                  />
                </Tabs>
              </AppBar>
            ) : (
              <AppBar position="static">
                <Tabs
                  value={value}
                  classes={{
                    root: classes.customTabRoot
                  }}
                  onChange={handleChange}
                  aria-label="simple tabs example"
                >
                  <Tab
                    label="Catalog"
                    {...a11yProps(0)}
                    onClick={() => handleActiveTab("All")}
                  />
                  <Tab
                    label="Subscribed"
                    {...a11yProps(1)}
                    onClick={() => handleActiveTab("Subscribed")}
                  />
                  <Tab
                    label="Active"
                    {...a11yProps(2)}
                    onClick={() => handleActiveTab("Active")}
                  />
                  <Tab
                    label="Inactive"
                    {...a11yProps(3)}
                    onClick={() => handleActiveTab("Inactive")}
                  />
                  <Tab
                    label="Scheduled"
                    {...a11yProps(4)}
                    onClick={() => handleActiveTab("Schedule")}
                  />
                </Tabs>
              </AppBar>
            )}
            <div className="topnav">
              <div className="search-container">
                <form action="">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M18.4163 16.7497L14.4163 12.7497C15.333 11.4997 15.833 9.99967 15.833 8.33301C15.833 4.16634 12.4997 0.833008 8.33301 0.833008C4.16634 0.833008 0.833008 4.16634 0.833008 8.33301C0.833008 12.4997 4.16634 15.833 8.33301 15.833C9.99967 15.833 11.4997 15.333 12.7497 14.4163L16.7497 18.4163C17.2497 18.9163 17.9163 18.9163 18.4163 18.4163C18.833 17.9163 18.833 17.1663 18.4163 16.7497ZM8.33301 13.7497C5.33301 13.7497 2.91634 11.333 2.91634 8.33301C2.91634 5.33301 5.33301 2.91634 8.33301 2.91634C11.333 2.91634 13.7497 5.33301 13.7497 8.33301C13.7497 11.333 11.333 13.7497 8.33301 13.7497Z"
                      fill="#545454"
                    />
                  </svg>
                  <input
                    type="text"
                    className={classes.font}
                    placeholder=""
                    name="search"
                    value={searchText || ""}
                    onChange={handleChanges}
                  ></input>
                </form>
              </div>
              {(currentLabType == "All" ||
                    currentLabType == "Subscribed")}
                    {renderSideLabMenu()}
              {activeLab ? (
                <div className="search-container-right">
                  {/* <a
                    href="#"
                    className={classes.font}
                    style={currentLabType === "All" ? selectedlabTypeStyle : {}}
                    onClick={() => handleHeaderClick("All")}
                  >
                    All
                  </a> */}
                  {/* <a
                    href="#"
                    className={classes.font}
                    style={
                      currentLabType === "Active" ? selectedlabTypeStyle : {}
                    }
                    onClick={() => handleHeaderClick("Active")}
                  >
                    Active
                  </a>
                  <a
                    href="#"
                    className={classes.font}
                    style={
                      currentLabType === "InActive" ? selectedlabTypeStyle : {}
                    }
                    onClick={() => handleHeaderClick("InActive")}
                  >
                    Inactive
                  </a> */}
                </div>
              ) : null}
            </div>

            <TabPanel
              value={value}
              index={
                JSON.parse(localStorage.getItem("profileData")).privilege ===
                "admin"
                  ? 0
                  : 0
              }
              className="sandbox-first-tabs"
            >
              {cardData?.length === 0 && reservedIPLoaded && runningClusters ? (
                <h4 className={classes.font} style={{ fontWeight: 400 }}>
                  {" "}
                  No matches found.{" "}
                </h4>
              ) : (
                renderTemplateCards()
              )}
            </TabPanel>
            <TabPanel
              value={value}
              index={
                JSON.parse(localStorage.getItem("profileData")).privilege ===
                "admin"
                  ? ""
                  : 1
              }
              className="sandbox-first-tabs"
            >
              {subscribedList?.length === 0 &&
              reservedIPLoaded &&
              runningClusters ? (
                <h4 className={classes.font} style={{ fontWeight: 400 }}>
                  {" "}
                  No matches found.{" "}
                </h4>
              ) : (
                renderSubscribedCards()
              )}
            </TabPanel>

            <TabPanel
              value={value}
              index={
                JSON.parse(localStorage.getItem("profileData")).privilege ===
                "admin"
                  ? 1
                  : 2
              }
              className="sandbox-card"
            >
              {activeList?.length === 0 && activeList?.length < 1 ? (
                <h4 className={classes.font} style={{ fontWeight: 400 }}>
                  {" "}
                  No active Sandboxes.{" "}
                </h4>
              ) : (
                renderActiveCards()
              )
              // <LearningCards
              //     labFilter={currentLabType}
              //     offeringType={Offerings.POC_LIBRARY}
              //     privilege={profileObj.profileObj.privilege}
              //     labs={{
              //         activeLabs: activeListTemp.activeLabs,
              //         inactiveLabs: activeListTemp.inactiveLabs,
              //         allLabs: activeListTemp.allLabs,
              //     }}
              //     getLabIdfromLabIdentifier={getLabIdfromLabIdentifier}
              //     addHoursDetails={profileObj.profileObj.add_hours}
              //     onRowSelect={onRowSelect}
              // />
              }
            </TabPanel>
            <TabPanel
              value={value}
              index={
                JSON.parse(localStorage.getItem("profileData")).privilege ===
                "admin"
                  ? 2
                  : 3
              }
              className="sandbox-card"
            >
              {inActiveList?.length === 0 && inActiveList?.length < 1 ? (
                <h4 className={classes.font} style={{ fontWeight: 400 }}>
                  {" "}
                  No inactive Sandboxes.{" "}
                </h4>
              ) : (
                renderinActiveCards()
              )
            }
            </TabPanel>
            <TabPanel
              value={value}
              index={
                JSON.parse(localStorage.getItem("profileData")).privilege ===
                "admin"
                  ? 3
                  : 4
              }
            >
              <GridContainer>
                <GridItem xs={12}>
                  <ScheduleCluster
                    value="No matches found."
                    offeringType={Offerings.POC_LIBRARY.resource}
                    ScheduleSearchInput={searchText}
                  />
                </GridItem>
              </GridContainer>
            </TabPanel>
          </div>
        ) : (
          <Loader
            type={LOADER_TYPE}
            color={globalTheme.palette.secondary.main}
            width={50}
            height={50}
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%,-50%)"
            }}
          />
        )}
        <Chatbot/>
      </>
    );
  }
}
