import { SHOW_NOTIFICATION } from "action_creators/actioncreator";
// import style manually
import 'react-markdown-editor-lite/lib/index.css';

// Finish!

// This component creates a modal for Reserved IP with respective form fields, the
// modal is popped up during provision or resuming of a lab or poc.

import React, { Component, useState, useEffect } from "react";
import { SECONDARY_COLOR } from "global_constants/app_constants";
import Button from "@material-ui/core/Button";
// Formik
import { useFormik } from "formik";
import MuiPhoneNumber from "material-ui-phone-number";
import Datetime from "react-datetime";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import GridItem from "components/Grid/GridItem";
import { Api, Urls } from "config/api";
import Loader from "react-loader-spinner";
import LoaderHoc from "../../../components/HOC/LoaderHoc";
import { LOADER_TYPE } from "global_constants/app_constants";
import "views/ServiceOffering/LearningLab/acess labs/Overview.scss";
import NotificationAlert from "views/Components/NotificationAlert";
import { SDCloudBackendAPIWithToken } from "apis/backendAPI";
import Typography from "@material-ui/core/Typography";
import theme from "theme";
//API Call
import { CMSAPIWithToken } from "../../../apis/CMS";
//react 
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";


const inputProps = {
  disableAnimation: true,
  shrink: false
};

const customStyles = theme => ({
  mainContainer: {
    padding: "40px"
  },
  container: {
    marginTop: "20px"
  },
  formContainer: {
    width: "100%"
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  paper: {
    position: "absolute",
    width: 550,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    display: "block"
  },
  buttStyle: {
    background: theme.palette.buttonColor.main,
    "&:hover": {
      background: theme.palette.buttonColor.main
    }
  },
  firstTitleLabel: {
    padding: "0 15px"
  },
  titleLabel: {
    padding: "0 15px",
    marginTop: "30px"
  },
  errorText: {
    fontSize: "12px",
    color: theme.palette.error.main
  }
});

const AddNewSandboxType = props => {
  const useStyles = makeStyles(customStyles);
  const classes = useStyles();
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationType, setNotificationType] = useState("");
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    getAccountInfo();
  }, []);

  const formik = useFormik({
    enableReinitialize:true,
    initialValues: {
                type_name: "", 
              },
    onSubmit: (values) => {
      let payload = {
                type_name: values.type_name, 
              };

        CMSAPIWithToken() 
        .post("/poc/admin_sandboxes_types/",payload) 
        .then((resp) => resp.data.results)
        .then((data) => {
            const payload = {
                message: "Sandbox Type Created Successfully",
                color: "success",
            };
            payload.message && dispatch(SHOW_NOTIFICATION({ payload }));   
            history.push("/product-admin-sandboxes");
            window.location.reload()
        })
        .catch((err) => {
          let message = err.response.data.message;
          const payload = {
            message: message,
            color: "danger",
        };
        payload.message && dispatch(SHOW_NOTIFICATION({ payload })); 
        });
    },
    validate: (values) => {
      let errors = {};
      if (!values.type_name) {
        errors.type_name = "Required";
      }
      return errors;
    },
  });

 const getAccountInfo = () => {
    SDCloudBackendAPIWithToken()
      .get(Api.accountInfo)
      .then(res => {
      
      })
      .then(() => {
        setIsLoading(false);
      })
      .catch(err => {
        //console.log(err.response);
        setIsLoading(false);
      });
  };

let Cancelbutton=()=>{
  history.push("/product-admin-sandboxes");
  window.location.reload()
}

  return (
    // Form modal for sandbox create
    <div>
      <LoaderHoc isLoading={isLoading}>
        <Paper elevation={0}>
          <Grid
            container
            className={classes.mainContainer}
            style={{ marginTop: "2%" }}
          >
            <form
              onSubmit={formik.handleSubmit}
              className={classes.formContainer}
            >
              {/* <Typography variant="h4">{props.formTitle}</Typography> */}
              <Typography variant="h4" className={classes.firstTitleLabel}>
              Add New Sandbox Type
              </Typography>
              <Grid container className={classes.container}>
                <GridItem md={9}>
                  {formik.errors.type_name && formik.touched.type_name ? (
                    <TextField
                      type="text"
                      variant="filled"
                      label="Sandbox Type Name"
                      name="type_name"
                      fullWidth
                      InputLabelProps={inputProps}
                      value={formik.values.type_name}
                      onChange={formik.handleChange}
                      helperText={formik.errors.type_name}
                      onBlur={formik.handleBlur}
                      error
                    />
                  ) : (
                    <TextField
                      type="text"
                      variant="filled"
                      label="Sandbox Type Name"
                      name="type_name"
                      fullWidth
                      InputLabelProps={inputProps}
                      value={formik.values.type_name}
                      onChange={formik.handleChange}
                    />
                  )}
                </GridItem>
              </Grid>
              <Grid
                container
                justify="center"
                alignItems="center"
                style={{ marginTop: "20px", marginBottom: "30px" }}
              >
                <GridItem xs={12} sm={6} md={3} lg={3}>
                  {!btnDisabled ? (
                    <Button
                      variant="contained"
                      color="secondary"
                      disableElevation
                      size="large"
                      className={classes.buttStyle}
                      fullWidth
                      disableFocusRipple
                      type="submit"
                    >
                      Submit
                    </Button>
                  ) : null}
                  {btnDisabled ? (
                    <Loader
                      type={LOADER_TYPE}
                      color={theme.palette.secondary.main}
                      width={50}
                      height={50}
                      style={{ float: "right", marginRight: "15px" }}
                    />
                  ) : null}
                </GridItem>
                <GridItem xs={12} sm={6} md={3} lg={3}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    disableElevation
                    size="large"
                    // className={classes.buttStyle}
                    fullWidth
                    onClick={Cancelbutton}
                    disableFocusRipple
                    type="submit"
                  >
                    Cancel
                  </Button>
                </GridItem>
              </Grid>

              <NotificationAlert
                notificationType={notificationType}
                showNotification={showNotification}
                message={notificationMessage}
              />
            </form>
          </Grid>
        </Paper>
      </LoaderHoc>
    </div>
  );
};

export default AddNewSandboxType;
